import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import {
	CUSTOM_ERROR_MESSAGES,
	NgBootstrapFormValidationModule,
} from "ng-bootstrap-form-validation";
import { AppRoutingModule } from "./app-routing.module";
import { LayoutComponent } from "./layout/layout.component";
import { NgbCarouselModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { CategoryModule } from "./modules/category/category.module";
import { ToastrModule } from "ngx-toastr";
import { ProductModule } from "./modules/product/product.module";
import { ClientModule } from "./modules/client/client.module";

import ptBr from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";
import { SubCategoryModule } from "./modules/subcategory/subcategory.module";
import { BannerModule } from "./modules/banner/banner.module";
import { DeviceDetectorModule } from "ngx-device-detector";
import { AreaEmailModule } from "./modules/area-email/area-email.module";
import { ContactAdmModule } from "./modules/contact-adm/contact-adm.module";
import { NewsletterModule } from "./modules/newsletter/newsletter.module";
import { OrderAdmModule } from "./modules/order-adm/order-adm.module";
import { DirectClientModule } from "./modules/direct-client/direct-client.module";
import { CUSTOM_ERRORS } from "./_shared/providers/custom-errors.provider";
import { DirectProductModule } from "./modules/direct-product/direct-product.module";
import { DirectOrderAdmModule } from "./modules/direct-order-adm/direct-order-adm.module";
import { GA_TOKEN, GoogleAnalyticsModule } from "angular-ga";

registerLocaleData(ptBr);

@NgModule({
	declarations: [AppComponent, HomeComponent, LayoutComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
		NgbModule,
		FormsModule,
		ApiAuthorizationModule,
		NgxSpinnerModule,
		AppRoutingModule,
		CategoryModule,
		SubCategoryModule,
		ProductModule,
		ClientModule,
		BannerModule,
		AreaEmailModule,
		ContactAdmModule,
		NewsletterModule,
		OrderAdmModule,
		DirectClientModule,
		DirectProductModule,
		DirectOrderAdmModule,

		NgBootstrapFormValidationModule.forRoot(),
		NgbCarouselModule,
		DeviceDetectorModule.forRoot(),
		ToastrModule.forRoot({
			timeOut: 5000,
			positionClass: "toast-top-center",
			preventDuplicates: true,
			progressBar: true,
			countDuplicates: true,
			resetTimeoutOnDuplicate: true,
			closeButton: true,
		}),
		GoogleAnalyticsModule.forRoot(),
	],
	exports: [NgBootstrapFormValidationModule],
	providers: [
		{ provide: LOCALE_ID, useValue: "pt" },
		{ provide: GA_TOKEN, useValue: "GTM-PQH9CHPD" },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
