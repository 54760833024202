import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientRegisterComponent } from './register/client-register.component';
import { ClientViewComponent } from './view/client-view.component';

const routes: Routes = [
	{
		path: 'view',
		component: ClientViewComponent
	},
	{
		path: 'register',
		component: ClientRegisterComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ClientRoutingModule { }
