import { FindOption } from './find-option.model';

export class QueryOption extends FindOption {
    where?: string;

    page?: number;

    limit?: number;

    includeImages?: boolean;
}


export class WhereCondition {
    col: string;
    op: string;
    value?: string | number | boolean | any;

    constructor(col: string, op: string, value?: string | number | boolean | any){
        this.col = col;
        this.op = op;
        this.value = value;
    }
}

