import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CategoryRegisterComponent } from './register/category-register.component';
import { CategoryViewComponent } from './view/category-view.component';

const routes: Routes = [
	{
		path: 'view',
		component: CategoryViewComponent
	},
	{
		path: 'register',
		component: CategoryRegisterComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CategoryRoutingModule { }
