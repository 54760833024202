import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-confirmation',
	templateUrl: './confirmation.component.html',
	styleUrls: ['./confirmation.component.less']
})
export class ConfirmationComponent {
	@Input()
	title;

    @Input()
    question;

    @Input()
    btnCancel = "Cancelar";

    @Input()
    btnOk = "Aceitar";

    constructor(
        public modal: NgbActiveModal
    ){

    }
}
