import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/_shared/shared.module';
import { CategoryRoutingModule } from './category-routing';
import { CategoryRegisterComponent } from './register/category-register.component';
import { CategoryViewComponent } from './view/category-view.component';

@NgModule({
	imports: [
		SharedModule,
		CategoryRoutingModule,
		NgxDatatableModule
	],
	declarations: [CategoryViewComponent, CategoryRegisterComponent],
	entryComponents: [CategoryRegisterComponent],
	exports: []
})
export class CategoryModule { }
