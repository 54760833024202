import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { Order } from "src/app/site/_models/order.model";
import { FindOption } from "src/app/_shared/model/find-option.model";
import { OrderAdmService } from "../services/order-adm.service";

@Component({
	selector: "app-order-adm-register",
	templateUrl: "./order-adm-register.component.html",
	styleUrls: ["./order-adm-register.component.less"],
})
export class OrderAdmRegisterComponent implements OnInit {
	@Input()
	id: string;

	print = false;

	order: Order = null;

	constructor(
		private orderAdmService: OrderAdmService,
		private spinner: NgxSpinnerService,
		public ngbActiveModal: NgbActiveModal
	) {}

	ngOnInit(): void {
		this.loadData();
	}

	private loadData() {
		if (this.id) {
			this.spinner.show();

			const findOptions: FindOption = {};
			this.orderAdmService
				.getById(this.id)
				.then((data) => {
					this.order = data;
				})
				.finally(() => this.spinner.hide());
		}
	}

	printPage() {
		this.print = true;
		setTimeout(() => {
			window.print();
			this.print = false;
		}, 500);
	}
}
