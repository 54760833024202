import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { order } from 'scriptjs';
import { Client } from 'src/app/site/_models/client.model';
import { Order } from 'src/app/site/_models/order.model';
import { ContactService } from 'src/app/site/_services/contact.service';
import { PaymentTypePipe } from 'src/app/_shared/pipes/payment-type.pipe';
import { PhonePipe } from 'src/app/_shared/pipes/phone.pipe';
import { YesNoPipe } from 'src/app/_shared/pipes/yes-no.pipe';
import { ViewComponent } from 'src/app/_shared/_core/view-component';
import { OrderMovementService } from '../order-movement/order-movement.service';
import { OrderAdmRegisterComponent } from '../register/order-adm-register.component';
import { OrderAdmService } from '../services/order-adm.service';

@Component({
	selector: 'app-order-adm-view',
	templateUrl: './order-adm-view.component.html',
	styleUrls: ["./order-adm-view.component.less"],
})
export class OrderAdmViewComponent extends ViewComponent implements OnInit {
	rows = [];

	ordersResult = {
		awaitPayment: [],
		approved: [],
		inProduction: [],
		inTransport: [],
		delivered: [],
		cancelled: []
	};

	loadingIndicator = true;
	reorderable = true;
	columns = [];
	columnMode = ColumnMode;
	active = 1;

	constructor(
		protected modalService: NgbModal,
		private orderAdmService: OrderAdmService,
		private orderMovementService: OrderMovementService,
		private spinner: NgxSpinnerService
	) {
		super(modalService);
	}

	ngOnInit(): void {
		this.columns = [
			{ prop: 'client.name', name: 'Cliente' },
			{ prop: 'client.email', name: 'E-mail' },
			{ prop: 'client.phone', name: 'Telefone', pipe: new PhonePipe()  },
			{ prop: 'paymentType', name: 'Meio de pagamento', pipe: new PaymentTypePipe() },
			{ prop: 'total', name: 'Total Site' },
			{ prop: 'netReceivedAmount', name: 'Total Recebido' },
			{ prop: 'installments', name: 'Parcelas' },
			{ prop: 'installmentAmount', name: 'Valor Parcela' },
			{ prop: 'totalPaidAmount', name: 'Total Pago' },
			{ cellTemplate: this.actionsTemplate }
		];

		this.loadData();
	}

	onEditAction(data: Order) {
		this.goToRegister(data.id);
	}

	onDeleteAction(data: Order) {

		//this.orderAdmService.delete(data.id).then(this.loadData.bind(this));
	};

	onCustomAction(data: Order){
		this.orderMovementService.move(data).then(() => {
			this.loadData();
		});
	}

	// Go to register page
	goToRegister(id?: string) {
		const modal = this.modalService.open(OrderAdmRegisterComponent, { windowClass: 'full-modal' });
		modal.result.then(this.loadData.bind(this));

		if (id) {
			modal.componentInstance.id = id;
		}
	}

	private async loadData() {
		this.loadingIndicator = true;
		this.spinner.show();

		this.ordersResult = {
			awaitPayment: await this.orderAdmService.getFromType("awaitingPayment"),
			approved: await this.orderAdmService.getFromType("approved"),
			inProduction: await this.orderAdmService.getFromType("inProduction"),
			inTransport: await this.orderAdmService.getFromType("inTransport"),
			delivered: await this.orderAdmService.getFromType("delivered"),
			cancelled: await this.orderAdmService.getFromType("cancelled")
		}


		this.loadingIndicator = false;
		this.spinner.hide();

		// this.orderAdmService.query().then((data) => {
		// 	this.rows = data.results;
		// }).finally(() => {
		// 	this.loadingIndicator = false;
		// 	this.spinner.hide();
		// });
	}
}