import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/_shared/shared.module';
import { DirectProductRoutingModule } from './direct-product-routing';
import { DirectProductViewComponent } from './view/direct-product-view.component';
import { DirectProductRegisterComponent } from './register/direct-product-register.component';
import { QuillModule } from 'ngx-quill';

@NgModule({
	imports: [
		SharedModule,
		DirectProductRoutingModule,
		NgxDatatableModule,
		QuillModule
	],
	declarations: [DirectProductViewComponent, DirectProductRegisterComponent],
	entryComponents: [DirectProductViewComponent, DirectProductRegisterComponent],
	exports: []
})
export class DirectProductModule { }
