import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Order } from "src/app/site/_models/order.model";
import { DirectOrderAdmService } from "../services/direct-order-adm.service";

@Component({
	selector: "app-direct-order-movement",
	templateUrl: "./direct-order-movement.component.html",
	styleUrls: ["./direct-order-movement.component.less"],
})
export class DirectOrderMovementComponent implements OnInit {
	@Input()
	order: Order;

	orderStatus: string;

	newStatus: string;

	listStatus = [
		{
			id: "awaitConfirmation",
			name: "Aguardando Confirmação",
		},
		{
			id: "awaitProduction",
			name: "Aguardando Produção",
		},
		{
			id: "inProduction",
			name: "Em Produção",
		},
		{
			id: "inTransport",
			name: "Em Transporte",
		},
		{
			id: "delivered",
			name: "Entregue",
		},
		{
			id: "cancelled",
			name: "Cancelado",
		},
	];

	constructor(
		public modal: NgbActiveModal,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
		private directOrderAdmService: DirectOrderAdmService
	) {}
	ngOnInit(): void {
		this.orderStatus = this.listStatus.find(
			(x) => x.id === this.order.status
		).name;
	}

	async onMove() {
		if (!this.newStatus) {
			this.toastr.error("É necessário informar o novo status!");
			return;
		}

		if (this.order.status != this.newStatus) {
			this.spinner.show();

			await this.directOrderAdmService
				.move(this.order.id, this.newStatus, this.order.status)
				.then(() => {
					this.modal.close();
				})
				.finally(() => {
					this.spinner.hide();
				});
		} else {
			this.toastr.warning("Escolha um status diferente do atual!");
		}
	}
}
