import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DirectClientViewComponent } from './view/direct-client-view.component';
import { DirectClientRegisterComponent } from './register/direct-client-register.component';

const routes: Routes = [
	{
		path: 'view',
		component: DirectClientViewComponent
	},
	{
		path: 'register',
		component: DirectClientRegisterComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DirectClientRoutingModule { }
