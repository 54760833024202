import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AreaEmailRegisterComponent } from './register/area-email-register.component';
import { AreaEmailViewComponent } from './view/area-email-view.component';

const routes: Routes = [
	{
		path: 'view',
		component: AreaEmailViewComponent
	},
	{
		path: 'register',
		component: AreaEmailRegisterComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AreaEmailRoutingModule { }
