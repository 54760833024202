import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductRegisterComponent } from './register/product-register.component';
import { ProductViewComponent } from './view/product-view.component';

const routes: Routes = [
	{
		path: 'view',
		component: ProductViewComponent
	},
	{
		path: 'register',
		component: ProductRegisterComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ProductRoutingModule { }
