import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ProductService } from "src/app/modules/product/services/product.service";
import { FindOption } from "src/app/_shared/model/find-option.model";
import {
	QueryOption,
	WhereCondition,
} from "src/app/_shared/model/query-option.model";
import { environment } from "src/environments/environment";
import { DirectOrder } from "src/app/site/_models/direct-order.model";

@Injectable({ providedIn: "root" })
export class DirectOrderAdmService {
	constructor(
		private httpClient: HttpClient,
		private productService: ProductService
	) {}

	query(queryOptions?: QueryOption): Promise<any> {
		const httpOptions: any = {
			params: { ...queryOptions },
		};

		return this.httpClient
			.get(`${environment.baseUrl}/v1/direct-order-adm/all`, httpOptions)
			.toPromise();
	}

	move(id: string, newStatus: string, oldStatus: string): Promise<any> {
		return this.httpClient
			.post(`${environment.baseUrl}/v1/direct-order-adm/move`, {
				id,
				newStatus,
				oldStatus,
			})
			.toPromise();
	}

	getFromType(status: string): Promise<any> {
		const queryOption: QueryOption = {};
		queryOption.where = JSON.stringify([
			[new WhereCondition("status", "=", status)],
		]);
		queryOption.expand = JSON.stringify(["directClient", ""]);
		queryOption.include = JSON.stringify(["directClient"]);

		const httpOptions: any = {
			params: queryOption,
		};

		return this.httpClient
			.get(`${environment.baseUrl}/v1/direct-order-adm/all`, httpOptions)
			.toPromise()
			.then((data: any) => {
				return data && data.results ? data.results : [];
			});
	}

	getById(id: string): Promise<DirectOrder> {
		const findOptions: FindOption = {};
		findOptions.include = JSON.stringify([
			"items.DirectProduct",
			"directClient",
		]);

		const params: any = {
			...findOptions,
		};
		return this.httpClient
			.get<DirectOrder>(
				`${environment.baseUrl}/v1/direct-order-adm/${id}`,
				{
					params,
				}
			)
			.toPromise();
	}
}
