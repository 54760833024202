import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

export enum ACTION {
    EDIT = 1,
    DELETE = 2,
    CUSTOM = 3,
}

@Component({
    selector: 'ngbd-modal-confirm',
    template: `
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">Confirmação!</h4>
      <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p><strong>Você confirma a exclusão do registro?</strong></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancelar</button>
      <button type="button" class="btn btn-danger" (click)="modal.close()">Confirmar</button>
    </div>
    `
})
export class NgbdModalConfirm {
    constructor(public modal: NgbActiveModal) { }
}

export abstract class ViewComponent {
    @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;

    abstract onEditAction(data);
    abstract onDeleteAction(data);
    protected onCustomAction?(data);

    constructor(protected modalService: NgbModal) {
    }

    onActionClick(action: ACTION, data: any) {
        switch (action) {
            case ACTION.EDIT: {
                this.onEditAction(data);
                break;
            }

            case ACTION.DELETE: {
                this.deleteAction(data);
                break;
            }

            case ACTION.CUSTOM: {
                this.onCustomAction(data);
                break;
            }            
        }
    }


    private deleteAction(data) {
        return this.modalService.open(NgbdModalConfirm, { backdrop: 'static' }).result.then(() => {
            this.onDeleteAction(data);
        }).catch(() => {});
    }
}