import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrderAdmRegisterComponent } from './register/order-adm-register.component';
import { OrderAdmViewComponent } from './view/order-adm-view.component';

const routes: Routes = [
	{
		path: 'view',
		component: OrderAdmViewComponent
	},
	{
		path: 'register',
		component: OrderAdmRegisterComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class OrderAdmRoutingModule { }
