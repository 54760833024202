import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'payment-type-pipe' })
export class PaymentTypePipe implements PipeTransform {
  transform(type: string) {
    switch(type){
      case 'credit_card':{
        return 'Cartão de Crédito';
      }
      case 'ticket': {
        return 'Boleto';
      }
      default: {
        return 'Desconhecido';
      }
    }
  }
}