import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryOption, WhereCondition } from 'src/app/_shared/model/query-option.model';
import { environment } from 'src/environments/environment';
import { Banner } from '../models/banner.model';

@Injectable({ providedIn: 'root' })
export class BannerService {

    constructor(private httpClient: HttpClient) {

    }

    saveOrUpdate(banner: Banner): Promise<any> {
        if (banner.id) {
            return this.update(banner.id, banner);
        }else{
            return this.save(banner);
        }
    }

    save(banner: Banner): Promise<any> {
        delete banner.id;
        return this.httpClient.post(`${environment.baseUrl}/v1/banners`, banner).toPromise();
    }

    update(id: string, banner: Banner): Promise<any> {
        return this.httpClient.put(`${environment.baseUrl}/v1/banners/${id}`, banner).toPromise();
    }

    query(queryOptions?: QueryOption): Promise<any> {
        const httpOptions: any = {
            params: { ...queryOptions }
        };

        return this.httpClient.get(`${environment.baseUrl}/v1/banners`, httpOptions).toPromise();
    };

    getAllByPosition(position: number): Promise<Banner[]> {
		const queryOption: QueryOption = {};
		queryOption.where = JSON.stringify([
			[new WhereCondition("active", "=", "true"), new WhereCondition("position", "=", position)],
		]);
		queryOption.includeImages = true;

        return this.query(queryOption).then((data) => data.results);
    };

    getById(id: string): Promise<Banner> {
        return this.httpClient.get<Banner>(`${environment.baseUrl}/v1/banners/${id}`).toPromise();
    };

    delete(id: string): Promise<any> {
        return this.httpClient.delete(`${environment.baseUrl}/v1/banners/${id}`).toPromise();
    }

    imageUrl(id: string) {
        return `${environment.baseUrl}/v1/banners/uploads/${id}`;
    }

    imageMobileUrl(id: string) {
        return `${environment.baseUrl}/v1/banners/uploads/mobile/${id}`;
    }
}