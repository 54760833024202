import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Category } from '../../category/models/category.model';
import { CategoryService } from '../../category/services/category.service';
import { SubCategory } from '../models/subcategory.model';
import { SubCategoryService } from '../services/subcategory.service';

@Component({
	selector: 'app-subcategory-register',
	templateUrl: './subcategory-register.component.html',
	styleUrls: ['./subcategory-register.component.less']
})
export class SubCategoryRegisterComponent implements OnInit {
	@Input()
	id: string;

	@Input()
	categoryId: string;

	subcategoryForm: FormGroup;

	category: Category = null;

	categories: Category[] = [];

	constructor(
		private formBuilder: FormBuilder,
		private categoryService: CategoryService,
		private subcategoryService: SubCategoryService,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
		private route: ActivatedRoute,
		public ngbActiveModal: NgbActiveModal,
	) {
		this.subcategoryForm = this.formBuilder.group({
			id: new FormControl(''),
			name: new FormControl('', [Validators.required]),
			category: new FormControl("", [Validators.required]),
			active: new FormControl(false)
		});
	}

	async ngOnInit(): Promise<void> {
		await this.loadData();
	}

	onSubmit() {
		const subcategory: SubCategory = this.subcategoryForm.value;
		this.spinner.show();

		this.subcategoryService.saveOrUpdate(subcategory)
			.then((result: SubCategory) => {
				this.toastr.success(`SubCategoria ${result.name} foi salva com sucesso!`);
				this.ngbActiveModal.close(result);
			})
			.finally(() => this.spinner.hide());
	}

	private async loadData() {
		this.spinner.show();

		if(this.id){
			await this.loadSubcategory();
		}

		await this.loadInitialData();

		this.spinner.hide();
	}

	private async loadInitialData(): Promise<any> {
		return this.categoryService.getById(this.categoryId).then((item) => {
			this.categories.push(item);
			this.subcategoryForm.controls['category'].setValue(item);
			return item;
		});
	}

	private async loadSubcategory(): Promise<any> {
		return this.subcategoryService.getById(this.id).then((data) => {
			for (const control in this.subcategoryForm.controls) {
				this.subcategoryForm.controls[control].setValue(data[control]);
			}
		});
	}
}
