import { Component, OnInit, Pipe, PipeTransform, SimpleChange, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadImageComponent } from 'src/app/_shared/components/upload-image/upload-image.component';
import { YesNoPipe } from 'src/app/_shared/pipes/yes-no.pipe';
import { ViewComponent } from 'src/app/_shared/_core/view-component';
import { Product } from '../models/product.model';
import { ProductRegisterComponent } from '../register/product-register.component';
import { ProductService } from '../services/product.service';

@Component({
	selector: 'app-product-view',
	templateUrl: './product-view.component.html'
})
export class ProductViewComponent extends ViewComponent implements OnInit {
    @ViewChild('gerenciarTemplate', { static: true }) gerenciarTemplate: TemplateRef<any>;

	rows = [];
	loadingIndicator = true;
	reorderable = true;
	columns = [];
	columnMode = ColumnMode;

	constructor(
		protected modalService: NgbModal,
		private productService: ProductService,
		private spinner: NgxSpinnerService
	) {
		super(modalService);
	}

	ngOnInit(): void {
		this.columns = [
			{ prop: 'name', name: 'Nome' },
			{ prop: 'active', name: 'Ativo', pipe: new YesNoPipe() },
			{ prop: 'featured', name: 'Destaque', pipe: new YesNoPipe() },
			{ name: 'Gerenciar Imagens', cellTemplate: this.gerenciarTemplate, headerClass: 'text-center', cellClass: 'text-center' },
			{ cellTemplate: this.actionsTemplate }
		];

		this.loadData();
	}

	onEditAction(data: Product) {
		this.goToRegister(data.id);
	}

	onDeleteAction(data: Product) {
		this.productService.delete(data.id).then(this.loadData.bind(this));
	};

	onManageImages(data: Product){
		const urlImage = this.productService.imageUrl(data.id);
		const modal = this.modalService.open(UploadImageComponent, { windowClass: 'full-modal' });	

		modal.componentInstance.title = `Imagens do Produto "${data.name}"`
		modal.componentInstance.url = urlImage;
		modal.componentInstance.distImage = 'products';
		modal.componentInstance.suggestionSize = '800x800';
	}

	// Go to register page
	goToRegister(id?: string) {
		const modal = this.modalService.open(ProductRegisterComponent, { windowClass: 'full-modal' });
		modal.result.then(this.loadData.bind(this));

		if (id) {
			modal.componentInstance.id = id;
		}
	}

	private loadData() {
		this.loadingIndicator = true;
		this.spinner.show();

		this.productService.query().then((data) => {
			this.rows = data.results;
		}).finally(() => {
			this.loadingIndicator = false;
			this.spinner.hide();
		});
	}
}