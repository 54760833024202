import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AreaEmail } from '../models/area-email.model';
import { AreaEmailService } from '../services/area-email.service';

@Component({
	selector: 'app-area-email-register',
	templateUrl: './area-email-register.component.html',
	styleUrls: ['./area-email-register.component.less']
})
export class AreaEmailRegisterComponent implements OnInit {
	@Input()
	id: string;

	areas: any[] = [];

	areaEmailForm: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private areaEmailService: AreaEmailService,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
		public ngbActiveModal: NgbActiveModal
	) {
		this.areaEmailForm = this.formBuilder.group({
			id: new FormControl(''),
			name: new FormControl('', [Validators.required]),
			area: new FormControl(null, [Validators.required]),
			email: new FormControl('', [Validators.required, Validators.email]),
			active: new FormControl(false),
		});
	}

	ngOnInit(): void {
		this.areas.push({
			id: 1,
			name: 'Fale Conosco'
		});

		this.areas.push({
			id: 2,
			name: 'Cadastre-se'
		});

		this.areas.push({
			id: 3,
			name: 'Pedidos'
		});

		this.areas.push({
			id: 4,
			name: 'Newsletter'
		});

		if (this.id) {
			this.loadData();
		}
	}

	onSubmit() {
		const areaEmail: AreaEmail = this.areaEmailForm.value;
		this.spinner.show();

		this.areaEmailService.saveOrUpdate(areaEmail)
			.then((result: AreaEmail) => {
				this.toastr.success(`Área E-mail ${result.name} foi salva com sucesso!`);
				this.ngbActiveModal.close(result);
			})
			.finally(() => this.spinner.hide());
	}

	private loadData() {
		this.spinner.show();

		this.areaEmailService.getById(this.id).then((data) => {
			for (const control in this.areaEmailForm.controls) {
				this.areaEmailForm.controls[control].setValue(data[control]);
			}
		}).finally(() => this.spinner.hide());
	}
}
