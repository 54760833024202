import { Component, Input, OnInit } from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { FindOption } from "src/app/_shared/model/find-option.model";
import {
	QueryOption,
	WhereCondition,
} from "src/app/_shared/model/query-option.model";
import { Category } from "../../category/models/category.model";
import { CategoryService } from "../../category/services/category.service";
import { SubCategory } from "../../subcategory/models/subcategory.model";
import { SubCategoryService } from "../../subcategory/services/subcategory.service";
import { Product } from "../models/product.model";
import { ProductService } from "../services/product.service";

@Component({
	selector: "app-product-register",
	templateUrl: "./product-register.component.html",
	styleUrls: ["./product-register.component.less"],
})
export class ProductRegisterComponent implements OnInit {
	@Input()
	id: string;

	productForm: FormGroup;

	categories: Category[] = [];

	subcategories: SubCategory[] = [];

	constructor(
		private formBuilder: FormBuilder,
		private productService: ProductService,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
		private categoryService: CategoryService,
		private subcategoryService: SubCategoryService,
		public ngbActiveModal: NgbActiveModal
	) {
		this.productForm = this.formBuilder.group({
			id: new FormControl(""),
			name: new FormControl("", [Validators.required]),
			minQuantity: new FormControl("", [
				Validators.required,
				Validators.min(1),
			]),
			quantity: new FormControl("", [
				Validators.required,
				Validators.min(1),
			]),
			amount: new FormControl("", [Validators.required]),
			promotion: new FormControl(""),
			description: new FormControl("", [Validators.required]),
			details: new FormControl(""),
			category: new FormControl("", [Validators.required]),
			subcategory: new FormControl(""),
			active: new FormControl(false),
			featured: new FormControl(false),
		});
	}

	ngOnInit(): void {
		const queryOption: QueryOption = {};
		queryOption.where = JSON.stringify([
			[new WhereCondition("active", "=", "true")],
		]);

		this.categoryService
			.query(queryOption)
			.then((data) => {
				this.categories = data.results;
			})
			.then(this.loadData.bind(this));
	}

	onSubmit() {
		const product: Product = this.productForm.value;
		product.amount = Number(product.amount);
		product.promotion = Number(product.promotion);
		this.spinner.show();

		this.productService
			.saveOrUpdate(product)
			.then((result: Product) => {
				this.toastr.success(
					`Produto ${result.name} foi salvo com sucesso!`
				);
				this.ngbActiveModal.close(result);
			})
			.finally(() => this.spinner.hide());
	}

	onChangeCategory(event){
		this.productForm.controls['subcategory'].setValue(null);
		this.loadSubcategory(event.id);
	}

	private async loadData() {
		if (this.id) {
			this.spinner.show();

			const findOptions: FindOption = {};
			findOptions.include = JSON.stringify(["category", "subcategory"]);
			this.productService
				.getById(this.id, findOptions)
				.then(async (data) => {
					await this.loadSubcategory(data.category.id);
					
					for (const control in this.productForm.controls) {
						this.productForm.controls[control].setValue(
							data[control]
						);
					}
				})
				.finally(() => this.spinner.hide());
		}
	}

	private async loadSubcategory(categoryId){
		const queryOption: QueryOption = {};
		queryOption.where = JSON.stringify([
			[new WhereCondition("categoryId", "=", categoryId), new WhereCondition("active", "=", "true")],
		]);

		return this.subcategoryService.query(queryOption).then((data) => {
			this.subcategories = data.results;
		}).finally(() => {
			this.spinner.hide();
		});
	}
}
