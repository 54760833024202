import { AfterViewInit, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { AuthenticationService } from "../_services/authentication.service";
import { first } from "rxjs/operators";
import { User } from "../_models/user.model";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.less"],
})
export class LoginComponent implements AfterViewInit {
	loginForm: FormGroup;
	error = "";

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService,
		private spinner: NgxSpinnerService
	) {
		// redirect to home if already logged in
		if (this.authenticationService.currentUserValue) {
			this.router.navigate(["/ger"]);
		}

		this.loginForm = this.formBuilder.group({
			username: new FormControl("", [Validators.required]),
			password: new FormControl("", [Validators.required]),
		});
	}
	ngAfterViewInit(): void {
		this.spinner.hide();
	}

	onSubmit() {
		if (this.loginForm.valid) {
			this.spinner.show();

			const user: User = this._v();
			this.authenticationService
				.login(user)
				.pipe(first())
				.subscribe({
					next: () => {
						this.spinner.hide();

						// get return url from route parameters or default to '/'
						const returnUrl = "/ger";
						this.router.navigate([returnUrl]);
					},
					error: (error) => {
						this.error = error;
						this.spinner.hide();
					},
				});
		}
	}

	_v() {
		return this.loginForm.value;
	}
}
