import { Component, Input, OnInit } from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DirectClientService } from "../services/direct-client.service";
import { DirectClient } from "../models/direct-client.model";
import { GovAddress, GovService } from "src/app/site/_services/gov.service";

@Component({
	selector: "app-direct-client-register",
	templateUrl: "./direct-client-register.component.html",
	styleUrls: ["./direct-client-register.component.less"],
})
export class DirectClientRegisterComponent implements OnInit {
	@Input()
	id: string;

	oldPostalCode: string = "";

	directClientForm: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private directClientService: DirectClientService,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
		private govService: GovService,
		public ngbActiveModal: NgbActiveModal
	) {
		this.directClientForm = this.formBuilder.group({
			id: new FormControl(""),
			name: new FormControl("", [Validators.required]),
			document: new FormControl("", [Validators.required]),
			phone: new FormControl(""),
			password: new FormControl(""),
			confirmPassword: new FormControl(""),
			state: new FormControl(""),
			city: new FormControl(""),
			neighborhood: new FormControl(""),
			street: new FormControl(""),
			streetNumber: new FormControl(""),
			zipcode: new FormControl(""),
			active: new FormControl(false),
		});

		if (!this.id) {
		}
	}

	ngOnInit(): void {
		if (this.id) {
			this.loadData();
		} else {
			this.directClientForm.controls["password"].setValidators([
				Validators.required,
			]);
			this.directClientForm.controls["confirmPassword"].setValidators([
				Validators.required,
				() => {
					return this.validateAreEqualEmail(
						this.directClientForm,
						"password",
						"confirmPassword",
						"Senha"
					);
				},
			]);
		}
	}

	onSubmit() {
		const directClient: DirectClient = this.directClientForm.value;
		this.spinner.show();

		this.directClientService
			.saveOrUpdate(directClient)
			.then((result: DirectClient) => {
				this.toastr.success(
					`Cliente ${result.name} foi salvo com sucesso!`
				);
				this.ngbActiveModal.close(result);
			})
			.finally(() => this.spinner.hide());
	}

	onSearchAddress() {
		const postalCode = this.directClientForm.get("zipcode").value;
		if (postalCode && postalCode != this.oldPostalCode) {
			this.oldPostalCode = postalCode;
			this.spinner.show();
			this.govService
				.getAddress(postalCode)
				.then((result: GovAddress) => {
					this.directClientForm.get("city").setValue(result.city);
					this.directClientForm.get("state").setValue(result.state);
				})
				.finally(() => {
					this.spinner.hide();
				});
		}
	}

	private loadData() {
		this.spinner.show();

		this.directClientService
			.getById(this.id)
			.then((data) => {
				for (const control in this.directClientForm.controls) {
					this.directClientForm.controls[control].setValue(
						data[control]
					);
				}
			})
			.finally(() => this.spinner.hide());
	}

	private validateAreEqualEmail(form: FormGroup, field1, field2, fieldEqual) {
		if (!form) {
			return null;
		}

		return form.get(field1).value != form.get(field2).value
			? {
					notEqual: {
						field1,
						field2,
						fieldEqual,
					},
			  }
			: null;
	}
}
