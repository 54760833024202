import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactAdmRegisterComponent } from './register/contact-adm-register.component';
import { ContactAdmViewComponent } from './view/contact-adm-view.component';

const routes: Routes = [
	{
		path: 'view',
		component: ContactAdmViewComponent
	},
	{
		path: 'register',
		component: ContactAdmRegisterComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ContactAdmRoutingModule { }
