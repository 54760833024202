import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FindOption } from "src/app/_shared/model/find-option.model";
import { environment } from "src/environments/environment";
import { Contact } from "../_models/contact.model";

@Injectable({ providedIn: "root" })
export class ContactService {
	constructor(private httpClient: HttpClient) {}

	create(contact: Contact): Promise<any> {
		return this.httpClient
			.post(`${environment.baseUrl}/v1/contacts`, contact)
			.toPromise();
	}

	updateToReady(id: string, entity: Contact): Promise<any> {
		entity.read = true;
		return this.httpClient
			.put(`${environment.baseUrl}/v1/contacts/${id}`, entity)
			.toPromise();
	}

	query(): Promise<any> {
		return this.httpClient
			.get(`${environment.baseUrl}/v1/contacts`)
			.toPromise();
	}

	getById(id: string, findOptions?: FindOption): Promise<Contact> {
		const params: any = {
			...findOptions,
		};
		return this.httpClient
			.get<Contact>(`${environment.baseUrl}/v1/contacts/${id}`, {
				params,
			})
			.toPromise();
	}

	delete(id: string): Promise<any> {
		return this.httpClient
			.delete(`${environment.baseUrl}/v1/contacts/${id}`)
			.toPromise();
	}
}
