import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryOption, WhereCondition } from 'src/app/_shared/model/query-option.model';
import { environment } from 'src/environments/environment';
import { Category } from '../models/category.model';

@Injectable({ providedIn: 'root' })
export class CategoryService {

    constructor(private httpClient: HttpClient) {

    }

    saveOrUpdate(category: Category): Promise<any> {
        if (category.id) {
            return this.update(category.id, category);
        }else{
            return this.save(category);
        }
    }

    save(category: Category): Promise<any> {
        delete category.id;
        return this.httpClient.post(`${environment.baseUrl}/v1/categories`, category).toPromise();
    }

    update(id: string, category: Category): Promise<any> {
        return this.httpClient.put(`${environment.baseUrl}/v1/categories/${id}`, category).toPromise();
    }

    query(queryOptions?: QueryOption): Promise<any> {
        const httpOptions: any = {
            params: { ...queryOptions }
        };

        return this.httpClient.get(`${environment.baseUrl}/v1/categories`, httpOptions).toPromise();
    };

    getAllFeatureds(): Promise<any> {
		const queryOption: QueryOption = {};
		queryOption.where = JSON.stringify([
			[new WhereCondition("active", "=", "true"), new WhereCondition("featured", "=", "true")],
		]);
		queryOption.includeImages = true;

        return this.query(queryOption);
    };

    getById(id: string): Promise<Category> {
        return this.httpClient.get<Category>(`${environment.baseUrl}/v1/categories/${id}`).toPromise();
    };

    delete(id: string): Promise<any> {
        return this.httpClient.delete(`${environment.baseUrl}/v1/categories/${id}`).toPromise();
    }

    imageUrl(id: string) {
        return `${environment.baseUrl}/v1/categories/uploads/${id}`;
    }
}