import { ErrorMessage } from 'ng-bootstrap-form-validation';

export const CUSTOM_ERRORS_PROVIDER_VALUE: ErrorMessage[] = [
	{
		error: 'required',
		format: (label: string, error: any) => {
			return `${label} é obrigatório.`;
		}
	}, {
		error: 'email',
		format: (label: string, error: any) => {
			return `${label} não parece um endereço de e-mail válido.`;
		}
	}, {
		error: 'minlength',
		format: (label: string, error: any) => {
			return `${label} necessita ao menos de ${error.requiredLength} caracteres.`;
		}
	}, {
		error: 'min',
		format: (label: string, error: any) => {
			return `${label} deve ser superior há ${error.min}.`;
		}
	}
];
