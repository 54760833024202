import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/_shared/shared.module';
import { AreaEmailRoutingModule } from './area-email-routing';
import { AreaEmailRegisterComponent } from './register/area-email-register.component';
import { AreaEmailViewComponent } from './view/area-email-view.component';

@NgModule({
	imports: [
		SharedModule,
		AreaEmailRoutingModule,
		NgxDatatableModule
	],
	declarations: [AreaEmailViewComponent, AreaEmailRegisterComponent],
	entryComponents: [AreaEmailRegisterComponent],
	exports: []
})
export class AreaEmailModule { }
