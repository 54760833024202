import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/_shared/shared.module';
import { ClientRoutingModule } from './client-routing';
import { ClientRegisterComponent } from './register/client-register.component';
import { ClientViewComponent } from './view/client-view.component';

@NgModule({
	imports: [
		SharedModule,
		ClientRoutingModule,
		NgxDatatableModule
	],
	declarations: [ClientViewComponent, ClientRegisterComponent],
	entryComponents: [ClientRegisterComponent],
	exports: []
})
export class ClientModule { }
