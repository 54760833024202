import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { Client } from 'src/app/site/_models/client.model';
import { ContactService } from 'src/app/site/_services/contact.service';
import { NewsletterService } from 'src/app/site/_services/newsletter.service';
import { YesNoPipe } from 'src/app/_shared/pipes/yes-no.pipe';
import { ViewComponent } from 'src/app/_shared/_core/view-component';
import { NewsletterRegisterComponent } from '../register/newsletter-register.component';

@Component({
	selector: 'app-newsletter-view',
	templateUrl: './newsletter-view.component.html'
})
export class NewsletterViewComponent extends ViewComponent implements OnInit {
	rows = [];
	loadingIndicator = true;
	reorderable = true;
	columns = [];
	columnMode = ColumnMode;

	constructor(
		protected modalService: NgbModal,
		private newsletterService: NewsletterService,
		private spinner: NgxSpinnerService
	) {
		super(modalService);
	}

	ngOnInit(): void {
		this.columns = [
			{ prop: 'name', name: 'Nome' },
			{ prop: 'email', name: 'E-mail' },
			{ prop: 'active', name: 'Ativo', pipe: new YesNoPipe() },
			{ cellTemplate: this.actionsTemplate }
		];

		this.loadData();
	}

	onEditAction(data: Client) {
		this.goToRegister(data.id);
	}

	onDeleteAction(data: Client) {
		this.newsletterService.delete(data.id).then(this.loadData.bind(this));
	};

	// Go to register page
	goToRegister(id?: string) {
		const modal = this.modalService.open(NewsletterRegisterComponent, { windowClass: 'full-modal' });
		modal.result.then(this.loadData.bind(this));

		if (id) {
			modal.componentInstance.id = id;
		}
	}

	private loadData() {
		this.loadingIndicator = true;
		this.spinner.show();

		this.newsletterService.query().then((data) => {
			this.rows = data.results;
		}).finally(() => {
			this.loadingIndicator = false;
			this.spinner.hide();
		});
	}
}