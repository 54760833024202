export class FindOption {
    select?: string;

    include?: string;

    expand?: string;

    sort?: any;

    includeDeleted?: boolean;

    includeImage?: boolean;
}