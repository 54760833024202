import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { AreaEmailPipe } from 'src/app/_shared/pipes/area-email.pipe';
import { YesNoPipe } from 'src/app/_shared/pipes/yes-no.pipe';
import { ViewComponent } from 'src/app/_shared/_core/view-component';
import { AreaEmail } from '../models/area-email.model';
import { AreaEmailRegisterComponent } from '../register/area-email-register.component';
import { AreaEmailService } from '../services/area-email.service';

@Component({
	selector: 'app-area-email-view',
	templateUrl: './area-email-view.component.html'
})
export class AreaEmailViewComponent extends ViewComponent implements OnInit {
	rows = [];
	loadingIndicator = true;
	reorderable = true;
	columns = [];
	columnMode = ColumnMode;

	constructor(
		protected modalService: NgbModal,
		private areaEmailService: AreaEmailService,
		private spinner: NgxSpinnerService,
		private router: Router
	) {
		super(modalService);
	}

	ngOnInit(): void {

		this.columns = [
			{ prop: 'name', name: 'Nome' },
			{ prop: 'email', name: 'E-mail' },
			{ prop: 'area', name: 'Área', pipe: new AreaEmailPipe() },
			{ prop: 'active', name: 'Ativo', pipe: new YesNoPipe() },
			// { prop: 'mobile', name: 'Versão mobile', pipe: new YesNoPipe() },
			{ cellTemplate: this.actionsTemplate, headerClass: 'text-center' }
		];

		this.loadData();
	}

	onEditAction(data: AreaEmail) {
		this.goToRegister(data.id);
	}

	onDeleteAction(data: AreaEmail) {
		this.areaEmailService.delete(data.id).then(this.loadData.bind(this));
	};

	// Go to register page
	goToRegister(id?: string) {
		const modal = this.modalService.open(AreaEmailRegisterComponent, { windowClass: 'full-modal' });
		modal.result.then(this.loadData.bind(this));

		if (id) {
			modal.componentInstance.id = id;
		}
	}

	private loadData() {
		this.loadingIndicator = true;
		this.spinner.show();

		this.areaEmailService.query().then((data) => {
			this.rows = data.results;
		}).finally(() => {
			this.loadingIndicator = false;
			this.spinner.hide();
		});
	}
}