import { Component, Input, OnInit } from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DirectClient } from "../../direct-client/models/direct-client.model";
import { DirectClientService } from "../../direct-client/services/direct-client.service";
import { DirectProductService } from "../services/direct-product.service";
import { DirectProduct } from "../models/direct-product.model";

@Component({
	selector: "app-direct-product-register",
	templateUrl: "./direct-product-register.component.html",
	styleUrls: ["./direct-product-register.component.less"],
})
export class DirectProductRegisterComponent implements OnInit {
	@Input()
	id: string;

	@Input()
	directClientId: string;

	directProductForm: FormGroup;

	directClient: DirectClient = null;

	directClients: DirectClient[] = [];

	constructor(
		private formBuilder: FormBuilder,
		private directClientService: DirectClientService,
		private directProductService: DirectProductService,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
		private route: ActivatedRoute,
		public ngbActiveModal: NgbActiveModal
	) {
		this.directProductForm = this.formBuilder.group({
			id: new FormControl(""),
			name: new FormControl("", [Validators.required]),
			minQuantity: new FormControl("", [
				Validators.required,
				Validators.min(1),
			]),
			quantity: new FormControl("", [
				Validators.required,
				Validators.min(1),
			]),
			stock: new FormControl("", [Validators.required]),
			amount: new FormControl("", [Validators.required]),
			promotion: new FormControl(""),
			description: new FormControl("", [Validators.required]),
			directClient: new FormControl("", [Validators.required]),
			active: new FormControl(false),
		});
	}

	async ngOnInit(): Promise<void> {
		await this.loadData();
	}

	onSubmit() {
		const directProduct: DirectProduct = this.directProductForm.value;
		this.spinner.show();

		this.directProductService
			.saveOrUpdate(directProduct)
			.then((result: DirectProduct) => {
				this.toastr.success(
					`Produto ${result.name} foi salvo com sucesso!`
				);
				this.ngbActiveModal.close(result);
			})
			.finally(() => this.spinner.hide());
	}

	private async loadData() {
		this.spinner.show();

		if (this.id) {
			await this.loadDirectProduct();
		}

		await this.loadInitialData();

		this.spinner.hide();
	}

	private async loadInitialData(): Promise<any> {
		return this.directClientService
			.getById(this.directClientId)
			.then((item) => {
				this.directClients.push(item);
				this.directProductForm.controls["directClient"].setValue(item);
				return item;
			});
	}

	private async loadDirectProduct(): Promise<any> {
		return this.directProductService.getById(this.id).then((data) => {
			for (const control in this.directProductForm.controls) {
				this.directProductForm.controls[control].setValue(
					data[control]
				);
			}
		});
	}
}
