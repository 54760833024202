import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CUSTOM_ERROR_MESSAGES, NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { CUSTOM_ERRORS_PROVIDER_VALUE } from 'src/common/providers/custom-errors.provider';
import { HeaderViewComponent } from './components/header-view/header-view.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { NgbdModalConfirm } from './_core/view-component';
import { ngfModule, ngf } from "angular-file"
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ConfirmationService } from './components/confirmation/confirmation.service';
import { PhonePipe } from './pipes/phone.pipe';
import { CpfCnpjPipe } from './pipes/cpf-cnpj.pipe';
import { YesNoPipe } from "./pipes/yes-no.pipe";
import { AreaEmailPipe } from "./pipes/area-email.pipe";
import { PaymentTypePipe } from "./pipes/payment-type.pipe";

export const customCurrencyMaskConfig = {
	align: "right",
	allowNegative: true,
	allowZero: true,
	decimal: ",",
	precision: 2,
	prefix: "R$ ",
	suffix: "",
	thousands: ".",
	nullable: true,
	min: null,
	max: null,
	inputMode: CurrencyMaskInputMode.FINANCIAL,
};

@NgModule({
	imports: [
		NgbModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgBootstrapFormValidationModule.forRoot(),
		NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
		NgSelectModule,
		ngfModule,
	],
	declarations: [
		HeaderViewComponent,
		UploadImageComponent,
		ConfirmationComponent,
		NgbdModalConfirm,
		PhonePipe,
		CpfCnpjPipe,
		YesNoPipe,
		AreaEmailPipe,
		PaymentTypePipe,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		NgBootstrapFormValidationModule,
		NgxCurrencyModule,
		NgSelectModule,
		ngfModule,
		HeaderViewComponent,
		NgbdModalConfirm,
		UploadImageComponent,
		ConfirmationComponent,
		PhonePipe,
		CpfCnpjPipe,
		YesNoPipe,
		AreaEmailPipe,
		PaymentTypePipe,
	],
	entryComponents: [
		NgbdModalConfirm,
		UploadImageComponent,
		ConfirmationComponent,
	],
	providers: [
		{
			provide: CUSTOM_ERROR_MESSAGES,
			useValue: CUSTOM_ERRORS_PROVIDER_VALUE,
			multi: true,
		},
		ConfirmationService,
	],
})
export class SharedModule {}
