import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadImageComponent } from 'src/app/_shared/components/upload-image/upload-image.component';
import { YesNoPipe } from 'src/app/_shared/pipes/yes-no.pipe';
import { ViewComponent } from 'src/app/_shared/_core/view-component';
import { Banner } from '../models/banner.model';
import { BannerRegisterComponent } from '../register/banner-register.component';
import { BannerService } from '../services/banner.service';

@Component({
	selector: 'app-banner-view',
	templateUrl: './banner-view.component.html'
})
export class BannerViewComponent extends ViewComponent implements OnInit {
    @ViewChild('gerenciarTemplate', { static: true }) gerenciarTemplate: TemplateRef<any>;

    @ViewChild('gerenciarMobileTemplate', { static: true }) gerenciarMobileTemplate: TemplateRef<any>;

	rows = [];
	loadingIndicator = true;
	reorderable = true;
	columns = [];
	columnMode = ColumnMode;

	constructor(
		protected modalService: NgbModal,
		private bannerService: BannerService,
		private spinner: NgxSpinnerService,
		private router: Router
	) {
		super(modalService);
	}

	ngOnInit(): void {

		this.columns = [
			{ prop: 'name', name: 'Nome' },
			{ prop: 'position', name: 'Posição', pipe: new YesNoPipe() },
			{ prop: 'active', name: 'Ativo', pipe: new YesNoPipe() },
			// { prop: 'mobile', name: 'Versão mobile', pipe: new YesNoPipe() },
			{ name: 'Gerenciar Imagens', cellTemplate: this.gerenciarTemplate, headerClass: 'text-center', cellClass: 'text-center' },
			{ name: 'Gerenciar Imagens Mobile', cellTemplate: this.gerenciarMobileTemplate, headerClass: 'text-center', cellClass: 'text-center' },
			{ cellTemplate: this.actionsTemplate, headerClass: 'text-center' }
		];

		this.loadData();
	}

	onEditAction(data: Banner) {
		this.goToRegister(data.id);
	}

	onDeleteAction(data: Banner) {
		this.bannerService.delete(data.id).then(this.loadData.bind(this));
	};

	onManageImages(data: Banner){
		const urlImage = this.bannerService.imageUrl(data.id);
		const modal = this.modalService.open(UploadImageComponent, { windowClass: 'full-modal' });	

		modal.componentInstance.title = `Imagens do Banner "${data.name}"`;
		modal.componentInstance.url = urlImage;
		modal.componentInstance.distImage = 'banners';
		modal.componentInstance.suggestionSize = '1920x500';
	}

	onManageMobileImages(data: Banner){
		const urlImage = this.bannerService.imageMobileUrl(data.id);
		const modal = this.modalService.open(UploadImageComponent, { windowClass: 'full-modal' });	

		modal.componentInstance.title = `Imagens Mobile do Banner "${data.name}"`;
		modal.componentInstance.url = urlImage;
		modal.componentInstance.distImage = 'banners';
		modal.componentInstance.suggestionSize = '1024x1024';
	}

	// Go to register page
	goToRegister(id?: string) {
		const modal = this.modalService.open(BannerRegisterComponent, { windowClass: 'full-modal' });
		modal.result.then(this.loadData.bind(this));

		if (id) {
			modal.componentInstance.id = id;
		}
	}

	private loadData() {
		this.loadingIndicator = true;
		this.spinner.show();

		this.bannerService.query().then((data) => {
			this.rows = data.results;
		}).finally(() => {
			this.loadingIndicator = false;
			this.spinner.hide();
		});
	}
}