import { NgModule } from "@angular/core";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { SharedModule } from "src/app/_shared/shared.module";
import { DirectOrderAdmRoutingModule } from "./direct-order-adm-routing";
import { DirectOrderAdmViewComponent } from "./view/direct-order-adm-view.component";
import { DirectOrderAdmRegisterComponent } from "./register/direct-order-adm-register.component";
import { DirectOrderMovementComponent } from "./order-movement/direct-order-movement.component";
import { DirectOrderAdmService } from "./services/direct-order-adm.service";
import { DirectOrderMovementService } from "./order-movement/direct-order-movement.service";

@NgModule({
	imports: [SharedModule, DirectOrderAdmRoutingModule, NgxDatatableModule],
	declarations: [
		DirectOrderAdmViewComponent,
		DirectOrderAdmRegisterComponent,
		DirectOrderMovementComponent,
	],
	entryComponents: [
		DirectOrderAdmRegisterComponent,
		DirectOrderMovementComponent,
	],
	providers: [DirectOrderAdmService, DirectOrderMovementService],
	exports: [],
})
export class DirectOrderAdmModule {}
