import { Component, OnInit, Pipe, PipeTransform, SimpleChange, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadImageComponent } from 'src/app/_shared/components/upload-image/upload-image.component';
import { YesNoPipe } from 'src/app/_shared/pipes/yes-no.pipe';
import { ViewComponent } from 'src/app/_shared/_core/view-component';
import { Category } from '../models/category.model';
import { CategoryRegisterComponent } from '../register/category-register.component';
import { CategoryService } from '../services/category.service';

@Component({
	selector: 'app-category-view',
	templateUrl: './category-view.component.html'
})
export class CategoryViewComponent extends ViewComponent implements OnInit {
    @ViewChild('gerenciarTemplate', { static: true }) gerenciarTemplate: TemplateRef<any>;

    @ViewChild('gerenciarSubcategoria', { static: true }) gerenciarSubcategoria: TemplateRef<any>;

	rows = [];
	loadingIndicator = true;
	reorderable = true;
	columns = [];
	columnMode = ColumnMode;

	constructor(
		protected modalService: NgbModal,
		private categoryService: CategoryService,
		private spinner: NgxSpinnerService,
		private router: Router
	) {
		super(modalService);
	}

	ngOnInit(): void {
		this.columns = [
			{ prop: 'name', name: 'Nome' },
			{ prop: 'active', name: 'Ativo', pipe: new YesNoPipe() },
			{ prop: 'featured', name: 'Destaque', pipe: new YesNoPipe() },
			{ name: 'Gerenciar Subcategorias', cellTemplate: this.gerenciarSubcategoria, headerClass: 'text-center', cellClass: 'text-center' },
			{ name: 'Gerenciar Imagens', cellTemplate: this.gerenciarTemplate, headerClass: 'text-center', cellClass: 'text-center' },
			{ cellTemplate: this.actionsTemplate }
		];

		this.loadData();
	}

	onEditAction(data: Category) {
		this.goToRegister(data.id);
	}

	onDeleteAction(data: Category) {
		this.categoryService.delete(data.id).then(this.loadData.bind(this));
	};

	onManageImages(data: Category){
		const urlImage = this.categoryService.imageUrl(data.id);
		const modal = this.modalService.open(UploadImageComponent, { windowClass: 'full-modal' });	

		modal.componentInstance.title = `Imagens da Categoria "${data.name}"`;
		modal.componentInstance.url = urlImage;
		modal.componentInstance.distImage = 'categories';
	}

	onManageSubcateries(data: Category){
		this.router.navigateByUrl(`/ger/category/${data.id}/subcategory/view`);
	}

	// Go to register page
	goToRegister(id?: string) {
		const modal = this.modalService.open(CategoryRegisterComponent, { windowClass: 'full-modal' });
		modal.result.then(this.loadData.bind(this));

		if (id) {
			modal.componentInstance.id = id;
		}
	}

	private loadData() {
		this.loadingIndicator = true;
		this.spinner.show();

		this.categoryService.query().then((data) => {
			this.rows = data.results;
		}).finally(() => {
			this.loadingIndicator = false;
			this.spinner.hide();
		});
	}
}