import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { Newsletter } from "src/app/site/_models/newsletter.model";
import { NewsletterService } from "src/app/site/_services/newsletter.service";
import { FindOption } from "src/app/_shared/model/find-option.model";

@Component({
	selector: "app-newsletter-register",
	templateUrl: "./newsletter-register.component.html",
	styleUrls: ["./newsletter-register.component.less"],
})
export class NewsletterRegisterComponent implements OnInit {
	@Input()
	id: string;

	newsletter: Newsletter = new Newsletter();

	constructor(
		private newsletterService: NewsletterService,
		private spinner: NgxSpinnerService,
		public ngbActiveModal: NgbActiveModal
	) {
	}

	ngOnInit(): void {
		this.loadData();
	}

	private loadData() {
		if (this.id) {
			this.spinner.show();
			
			const findOptions: FindOption = {};
			this.newsletterService
				.getById(this.id, findOptions)
				.then((data) => {
					this.newsletter = data;
				})
				.finally(() => this.spinner.hide());
		}
	}
}
