import { ClientAddress } from "./client-address.model";
import { OrderItem } from "./order-item.model";

export class Order {
	createdAt?: Date;
	paymentDateApproved?: Date;
	discount: number = 0;
	freight: number = 0;
	freightOption: string;
	id: string;
	items: OrderItem[];
	clientAddress: ClientAddress;
	itensTotal: number;
	paymentMethod: string;
	preference: string;
	status: string;
	subtotal: string;
	total: string;
}
