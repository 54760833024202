import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryOption, WhereCondition } from 'src/app/_shared/model/query-option.model';
import { environment } from 'src/environments/environment';
import { DirectClient } from '../models/direct-client.model';

@Injectable({ providedIn: 'root' })
export class DirectClientService {

    constructor(private httpClient: HttpClient) {

    }

    saveOrUpdate(directClient: DirectClient): Promise<any> {
        if (directClient.id) {
            return this.update(directClient.id, directClient);
        }else{
            return this.save(directClient);
        }
    }

    save(directClient: DirectClient): Promise<any> {
        delete directClient.id;
        return this.httpClient.post(`${environment.baseUrl}/v1/direct-clients`, directClient).toPromise();
    }

    update(id: string, directClient: DirectClient): Promise<any> {
        return this.httpClient.put(`${environment.baseUrl}/v1/direct-clients/${id}`, directClient).toPromise();
    }

    query(queryOptions?: QueryOption): Promise<any> {
        const httpOptions: any = {
            params: { ...queryOptions }
        };

        return this.httpClient.get(`${environment.baseUrl}/v1/direct-clients`, httpOptions).toPromise();
    };

    getById(id: string): Promise<DirectClient> {
        return this.httpClient.get<DirectClient>(`${environment.baseUrl}/v1/direct-clients/${id}`).toPromise();
    };

    delete(id: string): Promise<any> {
        return this.httpClient.delete(`${environment.baseUrl}/v1/direct-clients/${id}`).toPromise();
    }
}