import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/api-authorization/_helpers/auth.guard';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
	{
		path: "",
		loadChildren: "./site/site.module#SiteModule",
	},

	{
		path: "ger",
		component: LayoutComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: "home",
				component: HomeComponent,
			},
			{
				path: "category",
				loadChildren:
					"./modules/category/category.module#CategoryModule",
			},
			{
				path: "category/:categoryId/subcategory",
				loadChildren:
					"./modules/subcategory/subcategory.module#SubCategoryModule",
			},
			{
				path: "product",
				loadChildren: "./modules/product/product.module#ProductModule",
			},
			{
				path: "client",
				loadChildren: "./modules/client/client.module#ClientModule",
			},
			{
				path: "banner",
				loadChildren: "./modules/banner/banner.module#BannerModule",
			},
			{
				path: "area-email",
				loadChildren:
					"./modules/area-email/area-email.module#AreaEmailModule",
			},
			{
				path: "contacts",
				loadChildren:
					"./modules/contact-adm/contact-adm.module#ContactAdmModule",
			},
			{
				path: "newsletter",
				loadChildren:
					"./modules/newsletter/newsletter.module#NewsletterModule",
			},
			{
				path: "order-adm",
				loadChildren:
					"./modules/order-adm/order-adm.module#OrderAdmModule",
			},
			{
				path: "direct-client",
				loadChildren:
					"./modules/direct-client/direct-client.module#DirectClientModule",
			},
			{
				path: "direct-client/:directClientId/direct-product",
				loadChildren:
					"./modules/direct-product/direct-product.module#DirectProductModule",
			},
			{
				path: "direct-order-adm",
				loadChildren:
					"./modules/direct-order-adm/direct-order-adm.module#DirectOrderAdmModule",
			},
		],
	},

	// otherwise redirect to home
	{ path: "**", redirectTo: "" },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
