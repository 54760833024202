import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/_shared/shared.module';
import { OrderAdmRoutingModule } from './order-adm-routing';
import { OrderMovementComponent } from './order-movement/order-movement.component';
import { OrderMovementService } from './order-movement/order-movement.service';
import { OrderAdmRegisterComponent } from './register/order-adm-register.component';
import { OrderAdmService } from './services/order-adm.service';
import { OrderAdmViewComponent } from './view/order-adm-view.component';

@NgModule({
	imports: [
		SharedModule,
		OrderAdmRoutingModule,
		NgxDatatableModule
	],
	declarations: [OrderAdmViewComponent, OrderAdmRegisterComponent, OrderMovementComponent],
	entryComponents: [OrderAdmRegisterComponent, OrderMovementComponent],
	providers: [
		OrderAdmService,
		OrderMovementService
	],
	exports: []
})
export class OrderAdmModule { }
