import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Banner } from '../models/banner.model';
import { BannerService } from '../services/banner.service';

@Component({
	selector: 'app-banner-register',
	templateUrl: './banner-register.component.html',
	styleUrls: ['./banner-register.component.less']
})
export class BannerRegisterComponent implements OnInit {
	@Input()
	id: string;

	positions: any[] = [];

	bannerForm: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private bannerService: BannerService,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
		public ngbActiveModal: NgbActiveModal
	) {
		this.bannerForm = this.formBuilder.group({
			id: new FormControl(''),
			name: new FormControl('', [Validators.required]),
			link: new FormControl(''),
			position: new FormControl(null, [Validators.required]),
			active: new FormControl(false),
			mobile: new FormControl(false),
		});
	}

	ngOnInit(): void {
		this.positions.push({
			id: 1,
			name: 'Banner Início'
		});

		this.positions.push({
			id: 2,
			name: 'Linha 1'
		});

		this.positions.push({
			id: 3,
			name: 'Linha 2'
		});

		if (this.id) {
			this.loadData();
		}
	}

	onSubmit() {
		const banner: Banner = this.bannerForm.value;
		this.spinner.show();

		this.bannerService.saveOrUpdate(banner)
			.then((result: Banner) => {
				this.toastr.success(`Banner ${result.name} foi salva com sucesso!`);
				this.ngbActiveModal.close(result);
			})
			.finally(() => this.spinner.hide());
	}

	private loadData() {
		this.spinner.show();

		this.bannerService.getById(this.id).then((data) => {
			for (const control in this.bannerForm.controls) {
				this.bannerForm.controls[control].setValue(data[control]);
			}
		}).finally(() => this.spinner.hide());
	}
}
