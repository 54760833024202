import {ErrorMessage} from "ng-bootstrap-form-validation";

export const CUSTOM_ERRORS: ErrorMessage[] = [
  {
    error: "required",
    format: requiredFormat
  }, {
    error: "email",
    format: emailFormat
  }, {
    error: "minlength",
    format: minLengthFormat
  }, {
    error: "maxlength",
    format: maxLengthFormat
  }, {
    error: "notEqual",
    format: notEqualFormat
  }
];

export function requiredFormat(label: string, error: any): string {
  return `${label} é obrigatório!`;
}

export function emailFormat(label: string, error: any): string {
  return `${label} não é um e-mail válido.`;
}

export function minLengthFormat(label: string, error: any): string {
  return `${label} deve ter no mínimo ${error.requiredLength} caracteres.`;
}

export function maxLengthFormat(label: string, error: any): string {
  return `${label} deve ter no maxímo ${error.requiredLength} caracteres.`;
}

export function notEqualFormat(label: string, error: any): string {
  return `${label} e ${error.fieldEqual} são diferentes.`;
}

