import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from 'src/app/_shared/shared.module';
import { ProductRoutingModule } from './product-routing';
import { ProductRegisterComponent } from './register/product-register.component';
import { ProductViewComponent } from './view/product-view.component';

@NgModule({
	imports: [
		SharedModule,
		ProductRoutingModule,
		NgxDatatableModule,
		QuillModule,
	],
	declarations: [ProductViewComponent, ProductRegisterComponent],
	entryComponents: [ProductRegisterComponent],
	exports: []
})
export class ProductModule { }
