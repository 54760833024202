import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryOption, WhereCondition } from 'src/app/_shared/model/query-option.model';
import { environment } from 'src/environments/environment';
import { AreaEmail } from '../models/area-email.model';

@Injectable({ providedIn: 'root' })
export class AreaEmailService {

    constructor(private httpClient: HttpClient) {

    }

    saveOrUpdate(areaEmail: AreaEmail): Promise<any> {
        if (areaEmail.id) {
            return this.update(areaEmail.id, areaEmail);
        }else{
            return this.save(areaEmail);
        }
    }

    save(areaEmail: AreaEmail): Promise<any> {
        delete areaEmail.id;
        return this.httpClient.post(`${environment.baseUrl}/v1/area-email`, areaEmail).toPromise();
    }

    update(id: string, areaEmail: AreaEmail): Promise<any> {
        return this.httpClient.put(`${environment.baseUrl}/v1/area-email/${id}`, areaEmail).toPromise();
    }

    query(queryOptions?: QueryOption): Promise<any> {
        const httpOptions: any = {
            params: { ...queryOptions }
        };

        return this.httpClient.get(`${environment.baseUrl}/v1/area-email`, httpOptions).toPromise();
    };

    getAllByArea(area: number): Promise<AreaEmail[]> {
		const queryOption: QueryOption = {};
		queryOption.where = JSON.stringify([
			[new WhereCondition("active", "=", "true"), new WhereCondition("area", "=", area)],
		]);

        return this.query(queryOption).then((data) => data.results);
    };

    getById(id: string): Promise<AreaEmail> {
        return this.httpClient.get<AreaEmail>(`${environment.baseUrl}/v1/area-email/${id}`).toPromise();
    };

    delete(id: string): Promise<any> {
        return this.httpClient.delete(`${environment.baseUrl}/v1/area-email/${id}`).toPromise();
    }
}