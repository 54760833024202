import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DirectOrderAdmViewComponent } from "./view/direct-order-adm-view.component";
import { DirectOrderAdmRegisterComponent } from "./register/direct-order-adm-register.component";

const routes: Routes = [
	{
		path: "view",
		component: DirectOrderAdmViewComponent,
	},
	{
		path: "register",
		component: DirectOrderAdmRegisterComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class DirectOrderAdmRoutingModule {}
