import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/_shared/shared.module';
import { SubCategoryRegisterComponent } from './register/subcategory-register.component';
import { SubCategoryRoutingModule } from './subcategory-routing';
import { SubCategoryViewComponent } from './view/subcategory-view.component';

@NgModule({
	imports: [
		SharedModule,
		SubCategoryRoutingModule,
		NgxDatatableModule
	],
	declarations: [SubCategoryViewComponent, SubCategoryRegisterComponent],
	entryComponents: [SubCategoryViewComponent, SubCategoryRegisterComponent],
	exports: []
})
export class SubCategoryModule { }
