import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
	QueryOption,
	WhereCondition,
} from "src/app/_shared/model/query-option.model";
import { environment } from "src/environments/environment";
import { SubCategory } from "../models/subcategory.model";

@Injectable({ providedIn: "root" })
export class SubCategoryService {
	constructor(private httpClient: HttpClient) {}

	saveOrUpdate(subcategory: SubCategory): Promise<any> {
		if (subcategory.id) {
			return this.update(subcategory.id, subcategory);
		} else {
			return this.save(subcategory);
		}
	}

	save(subcategory: SubCategory): Promise<any> {
		delete subcategory.id;
		return this.httpClient
			.post(`${environment.baseUrl}/v1/subcategories`, subcategory)
			.toPromise();
	}

	update(id: string, subcategory: SubCategory): Promise<any> {
		return this.httpClient
			.put(`${environment.baseUrl}/v1/subcategories/${id}`, subcategory)
			.toPromise();
	}

	query(queryOptions?: QueryOption): Promise<any> {
		const httpOptions: any = {
			params: { ...queryOptions },
		};

		return this.httpClient
			.get(`${environment.baseUrl}/v1/subcategories`, httpOptions)
			.toPromise();
	}

	getAllFeatureds(): Promise<any> {
		const queryOption: QueryOption = {};
		queryOption.where = JSON.stringify([
			[
				new WhereCondition("active", "=", "true"),
				new WhereCondition("featured", "=", "true"),
			],
		]);

		return this.query(queryOption);
	}

	getById(id: string): Promise<SubCategory> {
		return this.httpClient
			.get<SubCategory>(`${environment.baseUrl}/v1/subcategories/${id}`)
			.toPromise();
	}

	delete(id: string): Promise<any> {
		return this.httpClient
			.delete(`${environment.baseUrl}/v1/subcategories/${id}`)
			.toPromise();
	}
}
