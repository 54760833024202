// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: true,
	baseUrl: "https://boxpaperembalagens.com.br/api/boxpaper",
	baseImageUrl: "https://boxpaperembalagens.com.br/tmp/img/",
	mercadoPagoKey: "APP_USR-6728ef7c-d7b0-4d96-b8bc-8cfb7b579d0d",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error'; // Included with Angular CLI.
