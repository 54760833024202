import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DirectOrder } from "src/app/site/_models/direct-order.model";
import { DirectOrderMovementComponent } from "./direct-order-movement.component";

@Injectable({ providedIn: "root" })
export class DirectOrderMovementService {
	constructor(protected modalService: NgbModal) {}

	move(order: DirectOrder): Promise<any> {
		const modal = this.modalService.open(DirectOrderMovementComponent, {
			backdrop: "static",
			centered: true,
			keyboard: false,
		});

		modal.componentInstance.order = order;

		return modal.result;
	}
}
