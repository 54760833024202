import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { NgxSpinnerService } from "ngx-spinner";
import { PhonePipe } from "src/app/_shared/pipes/phone.pipe";
import { ViewComponent } from "src/app/_shared/_core/view-component";
import { DirectOrderAdmService } from "../services/direct-order-adm.service";
import { DirectOrderMovementService } from "../order-movement/direct-order-movement.service";
import { DirectOrder } from "src/app/site/_models/direct-order.model";
import { DirectOrderAdmRegisterComponent } from "../register/direct-order-adm-register.component";

@Component({
	selector: "app-direct-order-adm-view",
	templateUrl: "./direct-order-adm-view.component.html",
	styleUrls: ["./direct-order-adm-view.component.less"],
})
export class DirectOrderAdmViewComponent
	extends ViewComponent
	implements OnInit
{
	rows = [];

	ordersResult = {
		awaitConfirmation: [],
		awaitProduction: [],
		inProduction: [],
		inTransport: [],
		delivered: [],
		cancelled: [],
	};

	loadingIndicator = true;
	reorderable = true;
	columns = [];
	columnMode = ColumnMode;
	active = 1;

	constructor(
		protected modalService: NgbModal,
		private directOrderAdmService: DirectOrderAdmService,
		private directOrderMovementService: DirectOrderMovementService,
		private spinner: NgxSpinnerService
	) {
		super(modalService);
	}

	ngOnInit(): void {
		this.columns = [
			{ prop: "directClient.name", name: "Cliente" },
			{ prop: "directClient.document", name: "CNPJ" },
			{
				prop: "directClient.phone",
				name: "Telefone",
				pipe: new PhonePipe(),
			},
			{ prop: "total", name: "Total Site" },
			{ cellTemplate: this.actionsTemplate },
		];

		this.loadData();
	}

	onEditAction(data: DirectOrder) {
		this.goToRegister(data.id);
	}

	onDeleteAction(data: DirectOrder) {
		//this.orderAdmService.delete(data.id).then(this.loadData.bind(this));
	}

	onCustomAction(data: DirectOrder) {
		this.directOrderMovementService.move(data).then(() => {
			this.loadData();
		});
	}

	// Go to register page
	goToRegister(id?: string) {
		const modal = this.modalService.open(DirectOrderAdmRegisterComponent, {
			windowClass: "full-modal",
		});
		modal.result.then(this.loadData.bind(this));

		if (id) {
			modal.componentInstance.id = id;
		}
	}

	private async loadData() {
		this.loadingIndicator = true;
		this.spinner.show();

		this.ordersResult = {
			awaitConfirmation: await this.directOrderAdmService.getFromType(
				"awaitConfirmation"
			),
			awaitProduction: await this.directOrderAdmService.getFromType(
				"awaitProduction"
			),
			inProduction: await this.directOrderAdmService.getFromType(
				"inProduction"
			),
			inTransport: await this.directOrderAdmService.getFromType(
				"inTransport"
			),
			delivered: await this.directOrderAdmService.getFromType(
				"delivered"
			),
			cancelled: await this.directOrderAdmService.getFromType(
				"cancelled"
			),
		};

		this.loadingIndicator = false;
		this.spinner.hide();
	}
}
