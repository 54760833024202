import { Component, Input, OnInit, Pipe, PipeTransform, SimpleChange, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadImageComponent } from 'src/app/_shared/components/upload-image/upload-image.component';
import { QueryOption, WhereCondition } from 'src/app/_shared/model/query-option.model';
import { YesNoPipe } from 'src/app/_shared/pipes/yes-no.pipe';
import { ViewComponent } from 'src/app/_shared/_core/view-component';
import {Location} from '@angular/common';
import { DirectProductService } from '../services/direct-product.service';
import { DirectProduct } from '../models/direct-product.model';
import { DirectProductRegisterComponent } from '../register/direct-product-register.component';

@Component({
	selector: 'app-direct-product-view',
	templateUrl: './direct-product-view.component.html',
	styleUrls: ["./direct-product-view.component.less"],
})
export class DirectProductViewComponent extends ViewComponent implements OnInit {
    @ViewChild('gerenciarTemplate', { static: true }) gerenciarTemplate: TemplateRef<any>;

	rows = [];
	loadingIndicator = true;
	reorderable = true;
	columns = [];
	columnMode = ColumnMode;

	directClientId = null;

	constructor(
		protected modalService: NgbModal,
		private directProductService: DirectProductService,
		private spinner: NgxSpinnerService,
		private location: Location,
		private router: Router,
		private route: ActivatedRoute,
	) {
		super(modalService);
	}

	ngOnInit(): void {
		this.directClientId = this.route.snapshot.paramMap.get('directClientId');

		this.columns = [
			{ prop: "name", name: "Nome" },
			{ prop: "stock", name: "Estoque" },
			{ prop: "active", name: "Ativo", pipe: new YesNoPipe() },
			{
				name: "Gerenciar Imagens",
				cellTemplate: this.gerenciarTemplate,
				headerClass: "text-center",
				cellClass: "text-center",
			},
			{ cellTemplate: this.actionsTemplate },
		];

		this.loadData();
	}

	onEditAction(data: DirectProduct) {
		this.goToRegister(data.id);
	}

	onDeleteAction(data: DirectProduct) {
		this.directProductService.delete(data.id).then(this.loadData.bind(this));
	};

	// Go to register page
	goToRegister(id?: string) {
		const modal = this.modalService.open(DirectProductRegisterComponent, { windowClass: 'full-modal' });
		modal.result.then(this.loadData.bind(this));

		// Set Data in modal
		modal.componentInstance.directClientId = this.directClientId;
		if (id) {
			modal.componentInstance.id = id;
		}
	}

	onGoBack(){
		try {
			this.location.back();
		} catch {
			this.router.navigateByUrl(`/ger/direct-client/view`);
		}
	}

	onManageImages(data: DirectProduct){
		const urlImage = this.directProductService.imageUrl(data.id);
		const modal = this.modalService.open(UploadImageComponent, { windowClass: 'full-modal' });	

		modal.componentInstance.title = `Imagens do Produto "${data.name}"`;
		modal.componentInstance.url = urlImage;
		modal.componentInstance.distImage = 'direct-products';
	}

	private loadData() {
		this.loadingIndicator = true;
		this.spinner.show();

		const queryOption: QueryOption = {};
		queryOption.where = JSON.stringify([
			[new WhereCondition("directClientId", "=", this.directClientId)],
		]);

		this.directProductService.query(queryOption).then((data) => {
			this.rows = data.results;
		}).finally(() => {
			this.loadingIndicator = false;
			this.spinner.hide();
		});
	}
}