import { Component } from "@angular/core";
import { User } from "src/api-authorization/_models/user.model";
import { AuthenticationService } from "src/api-authorization/_services/authentication.service";

@Component({
	selector: "app-layout-base",
	templateUrl: "./layout.component.html",
	styleUrls: ["./layout.component.less"],
})
export class LayoutComponent {
	currentUser: User;

	isCollapsed = false;

	constructor(private authenticationService: AuthenticationService) {
		this.authenticationService.currentUser.subscribe(
			(x) => (this.currentUser = x)
		);
	}

	logout() {
		this.authenticationService.logout();
	}
}
