import { Component, Input, OnInit, Pipe, PipeTransform, SimpleChange, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadImageComponent } from 'src/app/_shared/components/upload-image/upload-image.component';
import { QueryOption, WhereCondition } from 'src/app/_shared/model/query-option.model';
import { YesNoPipe } from 'src/app/_shared/pipes/yes-no.pipe';
import { ViewComponent } from 'src/app/_shared/_core/view-component';
import { SubCategory } from '../models/subcategory.model';
import { SubCategoryRegisterComponent } from '../register/subcategory-register.component';
import { SubCategoryService } from '../services/subcategory.service';
import {Location} from '@angular/common';

@Component({
	selector: 'app-subcategory-view',
	templateUrl: './subcategory-view.component.html',
	styleUrls: ["./subcategory-view.component.less"],
})
export class SubCategoryViewComponent extends ViewComponent implements OnInit {
	rows = [];
	loadingIndicator = true;
	reorderable = true;
	columns = [];
	columnMode = ColumnMode;

	categoryId = null;

	constructor(
		protected modalService: NgbModal,
		private subcategoryService: SubCategoryService,
		private spinner: NgxSpinnerService,
		private location: Location,
		private router: Router,
		private route: ActivatedRoute,
	) {
		super(modalService);
	}

	ngOnInit(): void {
		this.categoryId = this.route.snapshot.paramMap.get('categoryId');

		this.columns = [
			{ prop: 'name', name: 'Nome' },
			{ prop: 'active', name: 'Ativo', pipe: new YesNoPipe() },
			{ cellTemplate: this.actionsTemplate }
		];

		this.loadData();
	}

	onEditAction(data: SubCategory) {
		this.goToRegister(data.id);
	}

	onDeleteAction(data: SubCategory) {
		this.subcategoryService.delete(data.id).then(this.loadData.bind(this));
	};

	// Go to register page
	goToRegister(id?: string) {
		const modal = this.modalService.open(SubCategoryRegisterComponent, { windowClass: 'full-modal' });
		modal.result.then(this.loadData.bind(this));

		// Set Data in modal
		modal.componentInstance.categoryId = this.categoryId;
		if (id) {
			modal.componentInstance.id = id;
		}
	}

	onGoBack(){
		try {
			this.location.back();
		} catch {
			this.router.navigateByUrl(`/ger/category/view`);
		}
	}

	private loadData() {
		this.loadingIndicator = true;
		this.spinner.show();

		const queryOption: QueryOption = {};
		queryOption.where = JSON.stringify([
			[new WhereCondition("categoryId", "=", this.categoryId)],
		]);

		this.subcategoryService.query(queryOption).then((data) => {
			this.rows = data.results;
		}).finally(() => {
			this.loadingIndicator = false;
			this.spinner.hide();
		});
	}
}