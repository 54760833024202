import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Category } from '../models/category.model';
import { CategoryService } from '../services/category.service';

@Component({
	selector: 'app-category-register',
	templateUrl: './category-register.component.html',
	styleUrls: ['./category-register.component.less']
})
export class CategoryRegisterComponent implements OnInit {
	@Input()
	id: string;

	categoryForm: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private categoryService: CategoryService,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
		public ngbActiveModal: NgbActiveModal
	) {
		this.categoryForm = this.formBuilder.group({
			id: new FormControl(''),
			name: new FormControl('', [Validators.required]),
			active: new FormControl(false),
			featured: new FormControl(false),
		});
	}

	ngOnInit(): void {
		if (this.id) {
			this.loadData();
		}
	}

	onSubmit() {
		const category: Category = this.categoryForm.value;
		this.spinner.show();

		this.categoryService.saveOrUpdate(category)
			.then((result: Category) => {
				this.toastr.success(`Categoria ${result.name} foi salva com sucesso!`);
				this.ngbActiveModal.close(result);
			})
			.finally(() => this.spinner.hide());
	}

	private loadData() {
		this.spinner.show();

		this.categoryService.getById(this.id).then((data) => {
			for (const control in this.categoryForm.controls) {
				this.categoryForm.controls[control].setValue(data[control]);
			}
		}).finally(() => this.spinner.hide());
	}
}
