import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-header-view',
	templateUrl: './header-view.component.html',
	styleUrls: ['./header-view.component.less']
})
export class HeaderViewComponent implements OnInit {
	@Input()
	title;

	@Input()
	goBackTitle;

	@Input()
	icon;

	@Input()
	description;

	@Output()
	clickNew = new EventEmitter<any>();

	@Output()
	goBack = new EventEmitter<any>();

	// Local Variables
	showNewButtom = false;
	showGoBack = false;

	ngOnInit(): void {
		this.showNewButtom = this.clickNew.observers.length > 0;
		this.showGoBack = this.goBack.observers.length > 0;
	}

	onClickNew() {
		this.clickNew.emit();
	}

	onGoBack() {
		this.goBack.emit();
	}
}
