import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { YesNoPipe } from 'src/app/_shared/pipes/yes-no.pipe';
import { ViewComponent } from 'src/app/_shared/_core/view-component';
import { DirectClientService } from '../services/direct-client.service';
import { DirectClient } from '../models/direct-client.model';
import { DirectClientRegisterComponent } from '../register/direct-client-register.component';

@Component({
	selector: 'app-direct-client-view',
	templateUrl: './direct-client-view.component.html'
})
export class DirectClientViewComponent extends ViewComponent implements OnInit {
    @ViewChild('gerenciarProducts', { static: true }) gerenciarProducts: TemplateRef<any>;

	rows = [];
	loadingIndicator = true;
	reorderable = true;
	columns = [];
	columnMode = ColumnMode;

	constructor(
		protected modalService: NgbModal,
		private directClientService: DirectClientService,
		private spinner: NgxSpinnerService,
		private router: Router
	) {
		super(modalService);
	}

	ngOnInit(): void {
		this.columns = [
			{ prop: 'name', name: 'Nome' },
			{ prop: 'document', name: 'CNPJ' },
			{ prop: 'active', name: 'Ativo', pipe: new YesNoPipe() },
			{ name: 'Gerênciar Produtos', cellTemplate: this.gerenciarProducts },
			{ cellTemplate: this.actionsTemplate },
		];

		this.loadData();
	}

	onEditAction(data: DirectClient) {
		this.goToRegister(data.id);
	}

	onDeleteAction(data: DirectClient) {
		this.directClientService.delete(data.id).then(this.loadData.bind(this));
	};

	onManageProducts(data: DirectClient){
		this.router.navigateByUrl(`/ger/direct-client/${data.id}/direct-product/view`);
	}

	// Go to register page
	goToRegister(id?: string) {
		const modal = this.modalService.open(DirectClientRegisterComponent, { windowClass: 'full-modal' });
		modal.result.then(this.loadData.bind(this));

		if (id) {
			modal.componentInstance.id = id;
		}
	}

	private loadData() {
		this.loadingIndicator = true;
		this.spinner.show();

		this.directClientService.query().then((data) => {
			this.rows = data.results;
		}).finally(() => {
			this.loadingIndicator = false;
			this.spinner.hide();
		});
	}
}