import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewsletterRegisterComponent } from './register/newsletter-register.component';
import { NewsletterViewComponent } from './view/newsletter-view.component';

const routes: Routes = [
	{
		path: 'view',
		component: NewsletterViewComponent
	},
	{
		path: 'register',
		component: NewsletterRegisterComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class NewsletterRoutingModule { }
