import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'area-email-pipe' })
export class AreaEmailPipe implements PipeTransform {
  transform(area: number) {
  	if(area === 1){

      return `Fale Conosco`;      
    }else if(area === 2){

      return `Cadastre-se`;            
    }else if(area === 3){
      
      return `Pedidos`;      
    }else if(area === 4){

      return `Newsletter`;            
    } else {

      return `Desconhecido`;      
    }
  }
}