import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DirectProductViewComponent } from './view/direct-product-view.component';
import { DirectProductRegisterComponent } from './register/direct-product-register.component';

const routes: Routes = [
	{
		path: 'view',
		component: DirectProductViewComponent
	},
	{
		path: 'register',
		component: DirectProductRegisterComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DirectProductRoutingModule { }
