import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Order } from 'src/app/site/_models/order.model';
import { OrderAdmService } from '../services/order-adm.service';

@Component({
	selector: 'app-order-movement',
	templateUrl: './order-movement.component.html',
	styleUrls: ['./order-movement.component.less']
})
export class OrderMovementComponent implements OnInit {
	@Input()
	order: Order;

    orderStatus: string;

    newStatus: string;

    listStatus = [
        {
            id: 'awaitingPayment',
            name: 'Aguardando Pagamento'
        },
        {
            id: 'approved',
            name: 'Pagamento Aprovado'
        },
        {
            id: 'inProduction',
            name: 'Em Produção'
        },
        {
            id: 'inTransport',
            name: 'Em Transporte'
        },
        {
            id: 'delivered',
            name: 'Entregue'
        },
        {
            id: 'cancelled',
            name: 'Cancelado'
        }
    ];

    constructor(
        public modal: NgbActiveModal,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private orderAdmService: OrderAdmService
    ){

    }
    ngOnInit(): void {
        this.orderStatus = this.listStatus.find((x) => x.id === this.order.status).name;
    }

    async onMove(){
        if(!this.newStatus){
            this.toastr.error("É necessário informar o novo status!");
            return;
        }

        if(this.order.status != this.newStatus){
            this.spinner.show();

            await this.orderAdmService.move(this.order.id, this.newStatus, this.order.status).then(() =>{
                this.modal.close();
            }).finally(() => {
                this.spinner.hide();
            });

        } else {
            this.toastr.warning("Escolha um status diferente do atual!");
        }
    }
}
