import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { Contact } from "src/app/site/_models/contact.model";
import { ContactService } from "src/app/site/_services/contact.service";
import { FindOption } from "src/app/_shared/model/find-option.model";

@Component({
	selector: "app-contact-adm-register",
	templateUrl: "./contact-adm-register.component.html",
	styleUrls: ["./contact-adm-register.component.less"],
})
export class ContactAdmRegisterComponent implements OnInit {
	@Input()
	id: string;

	contact: Contact = new Contact();

	constructor(
		private contactService: ContactService,
		private spinner: NgxSpinnerService,
		public ngbActiveModal: NgbActiveModal
	) {
	}

	ngOnInit(): void {
		this.loadData();
	}

	private loadData() {
		if (this.id) {
			this.spinner.show();
			
			const findOptions: FindOption = {};
			this.contactService
				.getById(this.id, findOptions)
				.then((data) => {
					if(!data.read){
						this.contactService.updateToReady(data.id, data);
					}
					this.contact = data;
				})
				.finally(() => this.spinner.hide());
		}
	}
}
