import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationComponent } from "./confirmation.component";

@Injectable({ providedIn: "root" })
export class ConfirmationService {

	constructor(
		protected modalService: NgbModal
	) {
	}

    Open(title: string, question?: string, btnCancel?: string, btnOk?: string): Promise<any> {
		const modal = this.modalService.open(ConfirmationComponent, { backdrop: 'static', centered: true, keyboard: false });	

		modal.componentInstance.title = title;
		modal.componentInstance.question = question;
		modal.componentInstance.btnCancel = btnCancel;
		modal.componentInstance.btnOk = btnOk;

        return modal.result;
    }
}