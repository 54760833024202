import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/_shared/shared.module';
import { ContactAdmRoutingModule } from './contact-adm-routing';
import { ContactAdmRegisterComponent } from './register/contact-adm-register.component';
import { ContactAdmViewComponent } from './view/contact-adm-view.component';

@NgModule({
	imports: [
		SharedModule,
		ContactAdmRoutingModule,
		NgxDatatableModule
	],
	declarations: [ContactAdmViewComponent, ContactAdmRegisterComponent],
	entryComponents: [ContactAdmRegisterComponent],
	exports: []
})
export class ContactAdmModule { }
