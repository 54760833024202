import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

export class GovAddress {
	code: string;
	state: string;
	city: string;
	district: string;
	address: string;
}

@Injectable({ providedIn: "root" })
export class GovService {
	constructor(private httpClient: HttpClient) {}

	getAddress(postalCode: string): Promise<GovAddress> {
		return this.httpClient
			.get<GovAddress>(`https://ws.apicep.com/cep/${postalCode}.json`)
			.toPromise();
	}
}
