import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/_shared/shared.module';
import { DirectClientRoutingModule } from './direct-client-routing';
import { DirectClientViewComponent } from './view/direct-client-view.component';
import { DirectClientRegisterComponent } from './register/direct-client-register.component';
import { NgxMaskModule } from 'ngx-mask';
import { CUSTOM_ERROR_MESSAGES } from 'ng-bootstrap-form-validation';
import { CUSTOM_ERRORS } from 'src/app/_shared/providers/custom-errors.provider';

@NgModule({
	imports: [
		SharedModule,
		DirectClientRoutingModule,
		NgxDatatableModule,
		NgxMaskModule.forRoot()
	],
	declarations: [DirectClientViewComponent, DirectClientRegisterComponent],
	entryComponents: [DirectClientRegisterComponent],
	providers: [
		{ provide: CUSTOM_ERROR_MESSAGES, useValue: CUSTOM_ERRORS, multi: true },
	],
	exports: []
})
export class DirectClientModule { }
