export class Contact {      
    id?: string;  
    name: string;
    city: string;
    state: string;
    phone: string;
    email: string;
    subject: string;
    description: string;
    read?: boolean;
}