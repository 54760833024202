import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryOption, WhereCondition } from 'src/app/_shared/model/query-option.model';
import { environment } from 'src/environments/environment';
import { DirectProduct } from '../models/direct-product.model';

@Injectable({ providedIn: 'root' })
export class DirectProductService {

    constructor(private httpClient: HttpClient) {
    }

    saveOrUpdate(directProduct: DirectProduct): Promise<any> {
        if (directProduct.id) {
            return this.update(directProduct.id, directProduct);
        }else{
            return this.save(directProduct);
        }
    }

    save(directProduct: DirectProduct): Promise<any> {
        delete directProduct.id;
        return this.httpClient.post(`${environment.baseUrl}/v1/direct-products`, directProduct).toPromise();
    }

    update(id: string, directProduct: DirectProduct): Promise<any> {
        return this.httpClient.put(`${environment.baseUrl}/v1/direct-products/${id}`, directProduct).toPromise();
    }

    query(queryOptions?: QueryOption): Promise<any> {
        const httpOptions: any = {
            params: { ...queryOptions }
        };

        return this.httpClient.get(`${environment.baseUrl}/v1/direct-products`, httpOptions).toPromise();
    };

    getById(id: string): Promise<DirectProduct> {
        return this.httpClient.get<DirectProduct>(`${environment.baseUrl}/v1/direct-products/${id}`).toPromise();
    };

    delete(id: string): Promise<any> {
        return this.httpClient.delete(`${environment.baseUrl}/v1/direct-products/${id}`).toPromise();
    }

    imageUrl(id: string) {
        return `${environment.baseUrl}/v1/direct-products/uploads/${id}`;
    }
}