import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Order } from "src/app/site/_models/order.model";
import { OrderMovementComponent } from "./order-movement.component";

@Injectable({ providedIn: "root" })
export class OrderMovementService {

	constructor(
		protected modalService: NgbModal
	) {
	}

    move(order: Order): Promise<any> {
		const modal = this.modalService.open(OrderMovementComponent, { backdrop: 'static', centered: true, keyboard: false });	

		modal.componentInstance.order = order;

        return modal.result;
    }
}