import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/_shared/shared.module';
import { BannerRoutingModule } from './banner-routing';
import { BannerRegisterComponent } from './register/banner-register.component';
import { BannerViewComponent } from './view/banner-view.component';

@NgModule({
	imports: [
		SharedModule,
		BannerRoutingModule,
		NgxDatatableModule
	],
	declarations: [BannerViewComponent, BannerRegisterComponent],
	entryComponents: [BannerRegisterComponent],
	exports: []
})
export class BannerModule { }
