import { Component, Input, OnInit } from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Client } from 'src/app/site/_models/client.model';
import { ClientService } from "src/app/site/_services/client.service";
import { FindOption } from "src/app/_shared/model/find-option.model";
import {
	QueryOption,
	WhereCondition,
} from "src/app/_shared/model/query-option.model";

@Component({
	selector: "app-client-register",
	templateUrl: "./client-register.component.html",
	styleUrls: ["./client-register.component.less"],
})
export class ClientRegisterComponent implements OnInit {
	@Input()
	id: string;

	clientForm: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private clientService: ClientService,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
		public ngbActiveModal: NgbActiveModal
	) {
		this.clientForm = this.formBuilder.group({
			id: new FormControl(""),
			name: new FormControl("", [Validators.required]),
			document: new FormControl("", [
				Validators.required,
				Validators.minLength(11),
				Validators.maxLength(14),
			]),
			phone: new FormControl("", [Validators.maxLength(15)]),
			email: new FormControl("", [Validators.required, Validators.email]),
			confirmEmail: new FormControl("", [
				Validators.required,
				Validators.email,
				() => {
					return this.validateAreEqualEmail(
						this.clientForm,
						"email",
						"confirmEmail",
						"E-mail"
					);
				},
			]),
			password: new FormControl("", [Validators.required]),
			confirmPassword: new FormControl("", [
				Validators.required,
				() => {
					return this.validateAreEqualEmail(
						this.clientForm,
						"password",
						"confirmPassword",
						"Senha"
					);
				},
			]),
		});
	}

	ngOnInit(): void {
		this.loadData();
	}

	onSubmit() {
		const client: Client = this.clientForm.getRawValue();
		if(!client.addresses){
			client.addresses = [];
		}

		this.clientService
			.saveOrUpdate(client)
			.then((result: Client) => {
				this.toastr.success(
					`Cliente ${result.name} foi salvo com sucesso!`
				);
				this.ngbActiveModal.close(result);
			})
			.finally(() => this.spinner.hide());
	}

	private loadData() {
		if (this.id) {
			this.spinner.show();

			const findOptions: FindOption = {};
			this.clientService
				.getById(this.id, findOptions)
				.then((data) => {
					for (const control in this.clientForm.controls) {
						this.clientForm.controls[control].setValue(
							data[control]
						);
					}

					this.clientForm.controls['confirmEmail'].setValue(data.email);
					this.clientForm.controls['confirmPassword'].setValue(data.password);
				})
				.finally(() => this.spinner.hide());
		}
	}

	private validateAreEqualEmail(form: FormGroup, field1, field2, fieldEqual) {
		if (!form) {
			return null;
		}

		return form.get(field1).value != form.get(field2).value
			? {
					notEqual: {
						field1,
						field2,
						fieldEqual,
					},
			  }
			: null;
	}
}
