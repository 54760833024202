import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SubCategoryRegisterComponent } from './register/subcategory-register.component';
import { SubCategoryViewComponent } from './view/subcategory-view.component';

const routes: Routes = [
	{
		path: 'view',
		component: SubCategoryViewComponent
	},
	{
		path: 'register',
		component: SubCategoryRegisterComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SubCategoryRoutingModule { }
