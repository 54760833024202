import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FindOption } from 'src/app/_shared/model/find-option.model';
import { QueryOption, WhereCondition } from 'src/app/_shared/model/query-option.model';
import { environment } from 'src/environments/environment';
import { Product } from '../models/product.model';

@Injectable({ providedIn: 'root' })
export class ProductService {

    constructor(private httpClient: HttpClient) {

    }

    saveOrUpdate(product: Product): Promise<any> {
        if (product.id) {
            return this.update(product.id, product);
        }else{
            return this.save(product);
        }
    }

    save(entity: Product): Promise<any> {
        delete entity.id;
        return this.httpClient.post(`${environment.baseUrl}/v1/products`, entity).toPromise();
    }

    update(id: string, entity: Product): Promise<any> {
        return this.httpClient.put(`${environment.baseUrl}/v1/products/${id}`, entity).toPromise();
    }

    query(queryOptions?: QueryOption): Promise<any> {
        const httpOptions: any = {
            params: { ...queryOptions }
        };

        return this.httpClient.get(`${environment.baseUrl}/v1/products`, httpOptions).toPromise();
    };

    getAllFeatureds(): Promise<any> {
		const queryOption: QueryOption = {};
		queryOption.where = JSON.stringify([
			[new WhereCondition("active", "=", "true"), new WhereCondition("featured", "=", "true")],
		]);
		queryOption.includeImages = true;

        return this.query(queryOption);
    };

    getProducts(productsId: string[]): Promise<Product[]> {
        const queryOption: QueryOption = {};
		queryOption.where = JSON.stringify([
			[new WhereCondition("active", "=", "true"), new WhereCondition("id", "in", productsId)],
		]);
        queryOption.includeImages = true;
        
        return this.query(queryOption).then((data) => {
            return data.results;
        });
    }

    getProduct(productId: string): Promise<Product> {
        const queryOption: QueryOption = {};
		queryOption.where = JSON.stringify([
			[new WhereCondition("id", "=", productId)],
		]);
        queryOption.includeImages = true;
        
        return this.query(queryOption).then((data) => {
            if (data && data.results && data.results.length) {
                return data.results[0];
            } else {
                return null;
            }
        });
    }


    getById(id: string, findOptions?: FindOption): Promise<Product> {
        const params: any = {
            ...findOptions
        }
        return this.httpClient.get<Product>(`${environment.baseUrl}/v1/products/${id}`, { params }).toPromise();
    };

    delete(id: string): Promise<any> {
        return this.httpClient.delete(`${environment.baseUrl}/v1/products/${id}`).toPromise();
    }

    imageUrl(id: string) {
        return `${environment.baseUrl}/v1/products/uploads/${id}`;
    }
}