import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { FindOption } from "src/app/_shared/model/find-option.model";
import { DirectOrder } from "src/app/site/_models/direct-order.model";
import { DirectOrderAdmService } from "../services/direct-order-adm.service";

@Component({
	selector: "app-direct-order-adm-register",
	templateUrl: "./direct-order-adm-register.component.html",
	styleUrls: ["./direct-order-adm-register.component.less"],
})
export class DirectOrderAdmRegisterComponent implements OnInit {
	@Input()
	id: string;

	print = false;

	order: DirectOrder = null;

	constructor(
		private directOrderAdmService: DirectOrderAdmService,
		private spinner: NgxSpinnerService,
		public ngbActiveModal: NgbActiveModal
	) {}

	ngOnInit(): void {
		this.loadData();
	}

	private loadData() {
		if (this.id) {
			this.spinner.show();

			const findOptions: FindOption = {};
			this.directOrderAdmService
				.getById(this.id)
				.then((data) => {
					this.order = data;
				})
				.finally(() => this.spinner.hide());
		}
	}

	getStatus(status: string) {
		switch (status) {
			case "awaitConfirmation": {
				return "Aguar. Confirmação";
			}
			case "awaitProduction": {
				return "Aguar. Produção";
			}
			case "inProduction": {
				return "Em Produção";
			}
			case "inTransport": {
				return "Em Transporte";
			}
			case "delivered": {
				return "Entregue";
			}
			case "cancelled": {
				return "Cancelado";
			}
			default: {
				return "Entre em contato";
			}
		}
	}

	printPage() {
		this.print = true;
		setTimeout(() => {
			window.print();
			this.print = false;
		}, 500);
	}
}
