import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BannerRegisterComponent } from './register/banner-register.component';
import { BannerViewComponent } from './view/banner-view.component';

const routes: Routes = [
	{
		path: 'view',
		component: BannerViewComponent
	},
	{
		path: 'register',
		component: BannerRegisterComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class BannerRoutingModule { }
