import { HttpClient, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Image } from '../../model/image.model';

@Component({
	selector: 'app-upload-image',
	templateUrl: './upload-image.component.html',
	styleUrls: ['./upload-image.component.less']
})
export class UploadImageComponent implements OnInit {
	accept = '*';
	files: File[] = [];
	progress: number;
	hasBaseDropZoneOver: boolean = false
	httpEmitter: Subscription;
	httpEvent: HttpEvent<{}>;
	lastFileAt: Date;

	sendableFormData: FormData;
	dragFiles: any;
	validComboDrag: any;
	lastInvalids: any;
	fileDropDisabled: any;
	maxSize: any;
	baseDropValid: any;

	images: Image[] = [];
	baseUrl: string = environment.baseImageUrl;

	@Input()
	title: string = "Imagens";

	@Input()
	url;

	@Input()
	distImage;

	@Input()
	suggestionSize: string = "";

	constructor(
		private toastr: ToastrService,
		public ngbActiveModal: NgbActiveModal,
		public httpClient: HttpClient,
		private spinner: NgxSpinnerService
	) { }

	ngOnInit(): void {
		this.loadImages();
	}

	cancel() {
		this.progress = 0
		if (this.httpEmitter) {
			this.httpEmitter.unsubscribe()
		}
	}

	uploadFiles(): Subscription {
		const req = new HttpRequest<FormData>(
			'POST',
			this.url,
			this.sendableFormData,
			{
				reportProgress: true//, responseType: 'text'
			}
		);

		return this.httpEmitter = this.httpClient.request(req)
			.subscribe(
				event => {
					this.httpEvent = event

					if (event instanceof HttpResponse) {
						delete this.httpEmitter
						console.log('request done', event)
						this.files = [];
						this.toastr.success("Todos os arquivos foram enviados com sucesso!");
						this.loadImages();
					}
				},
				error => alert('Error Uploading Files: ' + error.message)
			)
	}

	getDate() {
		return new Date()
	}

	removeImage(image: Image){
		const indexItem = this.images.indexOf(image);
		this.images.splice(indexItem, 1);
		this.toastr.info("Imagem removida com sucesso!");
		this.httpClient.delete(`${this.url}/${image.id}`).toPromise();
	}

	private loadImages(){
		this.spinner.show();
		this.httpClient.get(this.url).toPromise().then((images: Image[]) => {
			this.images = images;
		}).finally(() => this.spinner.hide());
	}
}
