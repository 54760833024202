import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FindOption } from "src/app/_shared/model/find-option.model";
import { QueryResponse } from "src/app/_shared/model/query-response.model";
import { environment } from "src/environments/environment";
import { Client } from "../_models/client.model";

@Injectable({ providedIn: "root" })
export class ClientService {
	constructor(private httpClient: HttpClient) {}

	create(client: Client): Promise<any> {
		return this.httpClient
			.post(`${environment.baseUrl}/v1/clients`, client)
			.toPromise();
	}

	saveOrUpdate(client: Client): Promise<any> {
		if (client.id) {
			return this.update(client.id, client);
		} else {
			return this.save(client);
		}
	}

	save(entity: Client): Promise<any> {
		delete entity.id;
		return this.httpClient
			.post(`${environment.baseUrl}/v1/clients`, entity)
			.toPromise();
	}

	update(id: string, entity: Client): Promise<any> {
		return this.httpClient
			.put(`${environment.baseUrl}/v1/clients/${id}`, entity)
			.toPromise();
	}

	updateAccount(entity: Client): Promise<any> {
		return this.httpClient
			.put(`${environment.baseUrl}/v1/clients/account`, entity)
			.toPromise();
	}

	query(): Promise<any> {
		return this.httpClient
			.get(`${environment.baseUrl}/v1/clients`)
			.toPromise();
	}

	getById(id: string, findOptions?: FindOption): Promise<Client> {
		const params: any = {
			...findOptions,
		};
		return this.httpClient
			.get<Client>(`${environment.baseUrl}/v1/clients/${id}`, { params })
			.toPromise();
	}

	delete(id: string): Promise<any> {
		return this.httpClient
			.delete(`${environment.baseUrl}/v1/clients/${id}`)
			.toPromise();
	}
}
