import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FindOption } from "src/app/_shared/model/find-option.model";
import { environment } from "src/environments/environment";
import { Newsletter } from "../_models/newsletter.model";

@Injectable({ providedIn: 'root' })
export class NewsletterService {

    constructor(private httpClient: HttpClient) {	}

    create(newsletter: Newsletter): Promise<any> {
        return this.httpClient.post(`${environment.baseUrl}/v1/newsletter`, newsletter).toPromise();
    }

    update(id: string, entity: Newsletter): Promise<any> {
        return this.httpClient.put(`${environment.baseUrl}/v1/newsletter/${id}`, entity).toPromise();
    }

    query(): Promise<any> {
        return this.httpClient.get(`${environment.baseUrl}/v1/newsletter`).toPromise();
    };

    getById(id: string, findOptions?: FindOption): Promise<Newsletter> {
        const params: any = {
            ...findOptions
        }
        return this.httpClient.get<Newsletter>(`${environment.baseUrl}/v1/newsletter/${id}`, { params }).toPromise();
    };

    delete(id: string): Promise<any> {
        return this.httpClient.delete(`${environment.baseUrl}/v1/newsletter/${id}`).toPromise();
    }        
}

