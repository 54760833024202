import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/_shared/shared.module';
import { NewsletterRoutingModule } from './newsletter-routing';
import { NewsletterRegisterComponent } from './register/newsletter-register.component';
import { NewsletterViewComponent } from './view/newsletter-view.component';

@NgModule({
	imports: [
		SharedModule,
		NewsletterRoutingModule,
		NgxDatatableModule
	],
	declarations: [NewsletterViewComponent, NewsletterRegisterComponent],
	entryComponents: [NewsletterRegisterComponent],
	exports: []
})
export class NewsletterModule { }
