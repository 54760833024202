import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user.model';
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
	private currentUserSubject: BehaviorSubject<User>;
	public currentUser: Observable<User>;

	constructor(private http: HttpClient, private router: Router) {
		this.currentUserSubject = new BehaviorSubject<User>(
			JSON.parse(localStorage.getItem("currentUser"))
		);
		this.currentUser = this.currentUserSubject.asObservable();
	}

	public get currentUserValue(): User {
		return this.currentUserSubject.value;
	}

	login(user: User) {
		return this.http
			.post<any>(`${environment.baseUrl}/v1/auth/login-adm`, user)
			.pipe(
				map((userResult) => {
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					localStorage.setItem(
						"currentUser",
						JSON.stringify(userResult)
					);
					this.currentUserSubject.next(userResult);
					return user;
				})
			);
	}

	logout() {
		// remove user from local storage to log user out
		localStorage.removeItem("currentUser");
		this.currentUserSubject.next(null);
		this.router.navigate(["/login"]);
	}
}
